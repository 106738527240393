import { makeStyles, createStyles, Menu as MuiMenu } from '@material-ui/core'

import {
  EventNote as EventNoteIcon,
  ListAlt as ListAltIcon,
  Receipt as ReceiptIcon,
} from '@material-ui/icons'

import type { FC, ReactNode } from 'react'
import React, { useState } from 'react'
import { Button, Menu as RaMenu, MenuItemLink } from 'react-admin'

import adresse from '../ressources/adresse'

import anomalie from '../ressources/anomalie'
import appareil from '../ressources/appareil'
import catalog from '../ressources/catalog'

import combustible from '../ressources/combustible'
import complexite from '../ressources/complexite'
import contact from '../ressources/contact'

import emplacement from '../ressources/emplacement'
import hauteur from '../ressources/hauteur'
import interventions from '../ressources/intervention'

import marque from '../ressources/marque'
import materiauConduit from '../ressources/materiauConduit'
import pro from '../ressources/pro'

import produit from '../ressources/produit'
import ramoneDepuis from '../ressources/ramoneDepuis'
import section from '../ressources/section'
import typeClient from '../ressources/typeClient'
import typeEncaissement from '../ressources/typeEncaissement'
import typeInstallation from '../ressources/typeInstallation'
import typeTva from '../ressources/typeTva'
import user from '../ressources/user'
import zone from '../ressources/zone'

type Props = {
  logout?: ReactNode
  onMenuClick?: () => void
}

const menuStyles = makeStyles((theme) =>
  createStyles({
    menuContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '96px',
      height: '100%',
      marginBottom: 0,
      backgroundColor: theme.palette.grey['600'],
      marginTop: 0,
    },
    subMenuButton: {
      fontSize: '10px',
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    leftBar: {
      width: '100%',
      display: 'flex',
      height: '64px',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(1),
      color: theme.palette.background.paper,
      fontSize: '11px',
      '& .RaMenuItemLink-icon': {
        justifycontent: 'center',
      },
      '& .MuiSvgIcon-root': {
        fill: theme.palette.background.paper,
        marginBottom: theme.spacing(0.5),
        justifyContent: 'center',
        width: '100%',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:RaMenuItemLink-active': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
)

export const Menu: FC<Props> = (props) => {
  const { onMenuClick } = props
  return <DesktopMenu onMenuClick={onMenuClick} />
}

const DesktopMenu: FC<Props> = () => {
  const { leftBar, menuContainer, subMenuButton } = menuStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  function openMenu(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <RaMenu className={menuContainer}>
      <MenuItemLink
        to="/Dashboard"
        primaryText={'Dashboard'}
        leftIcon={<EventNoteIcon />}
        classes={{ root: leftBar }}
      />
      <MenuItemLink
        to="/Agenda"
        primaryText={'Agenda'}
        leftIcon={<EventNoteIcon />}
        classes={{ root: leftBar }}
      />
      <MenuItemLink
        to="/Invoices"
        primaryText="Facture"
        leftIcon={<ReceiptIcon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/ToBeDone"
        primaryText="Reste à faire"
        leftIcon={<ListAltIcon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/Intervention"
        primaryText="Interventions"
        leftIcon={<interventions.icon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/Pro"
        primaryText="Contrat / Syndic"
        leftIcon={<pro.icon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/Adress"
        primaryText="Adresses"
        leftIcon={<adresse.icon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/Contact"
        primaryText="Contacts"
        leftIcon={<contact.icon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/FullCatalogOnlyPrice"
        primaryText="Catalogue"
        leftIcon={<catalog.icon />}
        className={leftBar}
      />
      <MenuItemLink
        to="/UserExtranet"
        primaryText="Extranet"
        leftIcon={<catalog.icon />}
        className={leftBar}
      />
      <Button
        label="Gestion des données"
        onClick={openMenu}
        className={subMenuButton}
      />
      <MuiMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItemLink
          primaryText="Anomalie"
          to={`/Anomalie`}
          leftIcon={<anomalie.icon />}
        />
        <MenuItemLink
          primaryText="Appareil"
          to={`/Appareil`}
          leftIcon={<appareil.icon />}
        />
        <MenuItemLink
          primaryText="Combustible"
          to={`/Combustible`}
          leftIcon={<combustible.icon />}
        />
        <MenuItemLink
          primaryText="Complexite"
          to={`/Complexite`}
          leftIcon={<complexite.icon />}
        />
        <MenuItemLink
          primaryText="Emplacement"
          to={`/Emplacement`}
          leftIcon={<emplacement.icon />}
        />
        <MenuItemLink
          primaryText="Hauteur"
          to={`/Hauteur`}
          leftIcon={<hauteur.icon />}
        />
        <MenuItemLink
          primaryText="Marque"
          to={`/Marque`}
          leftIcon={<marque.icon />}
        />
        <MenuItemLink
          primaryText="MateriauConduit"
          to={`/MateriauConduit`}
          leftIcon={<materiauConduit.icon />}
        />
        <MenuItemLink
          primaryText="Produit"
          to={`/Produit`}
          leftIcon={<produit.icon />}
        />
        <MenuItemLink
          primaryText="RamoneDepui"
          to={`/RamoneDepui`}
          leftIcon={<ramoneDepuis.icon />}
        />
        <MenuItemLink
          primaryText="Section"
          to={`/Section`}
          leftIcon={<section.icon />}
        />
        <MenuItemLink
          primaryText="TypeClient"
          to={`/TypeClient`}
          leftIcon={<typeClient.icon />}
        />
        <MenuItemLink
          primaryText="TypeEncaissement"
          to={`/TypeEncaissement`}
          leftIcon={<typeEncaissement.icon />}
        />
        <MenuItemLink
          primaryText="TypeInstallation"
          to={`/TypeInstallation`}
          leftIcon={<typeInstallation.icon />}
        />
        <MenuItemLink
          primaryText="TypeTva"
          to={`/TypeTva`}
          leftIcon={<typeTva.icon />}
        />
        <MenuItemLink
          primaryText="User"
          to={`/User`}
          leftIcon={<user.icon />}
        />
        <MenuItemLink
          primaryText="Zone"
          to={`/Zone`}
          leftIcon={<zone.icon />}
        />
      </MuiMenu>

      {/*

  <GestionDataMenuItem onMenuClick={onMenuClick} /> */}
    </RaMenu>
  )
}
