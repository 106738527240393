import type { Intervention } from '@willig/types/api'
import React from 'react'
import type { EditProps } from 'react-admin'
import { FormWithRedirect, Edit } from 'react-admin'

import { InterventionFields } from './interventionForm'

export const RessourceEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <FormWithRedirect
        render={(renderProps) => {
          const { saving, handleSubmitWithRedirect, record } = renderProps
          return (
            <InterventionFields
              record={record as Intervention}
              isEdit
              saving={saving}
              handleSubmitWithRedirect={handleSubmitWithRedirect}
            />
          )
        }}
      />
    </Edit>
  )
}
