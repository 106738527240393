import { EnumContactType } from '@willig/types/api'
import type { FC } from 'react'
import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  BulkExportButton,
} from 'react-admin'

import type { ListProps, FilterProps } from 'react-admin'

import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'

import { DefaultPagination } from '../../components/defaultPagination'

const ContactFilters: FC<Partial<FilterProps>> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Nom" source="nom" alwaysOn />
      <TextInput label="Prenom" source="prenom" alwaysOn />
      <TextInput label="Mobile" source="mobile" alwaysOn />
      <TextInput label="Fixe" source="fixe" alwaysOn />
      <TextInput label="Email" source="email" />
      <SelectInput
        label={'Type'}
        source="type"
        choices={convertEnumAsOptionWithId(EnumContactType)}
      />
      <TextInput label="Raison sociale" source="raison_sociale" alwaysOn />
    </Filter>
  )
}

export const ListView = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={<ContactFilters />}
      bulkActionButtons={<BulkExportButton />}
      pagination={<DefaultPagination />}
      perPage={50}
    >
      <Datagrid rowClick="edit">
        <TextField source="nom" />
        <TextField source="prenom" />
        <TextField source="mobile" />
        <TextField source="fixe" />
        <TextField source="email" />
        <TextField source="type" />
        <TextField source="raison_sociale" />
      </Datagrid>
    </List>
  )
}
