import type { Intervention, Contact } from '@willig/types/api'
import type { ParsedFullInvoicesIntervention } from 'src/adapters/antiCorruptionLayerProxy'
import type { EntriesStatus } from 'src/ressources/intervention/interventionFormComponents/OtherRelationsActions'

export type InterventionCreate = Omit<
  Intervention,
  'status' | 'need_invoice' | 'fixed_slot'
> & {
  otherEntries?: EntriesStatus
}

export type InterventionDetail = {
  invoice: ParsedFullInvoicesIntervention['invoice']
  devis: ParsedFullInvoicesIntervention['devis']
  devis_complementaire: ParsedFullInvoicesIntervention['devis_complementaire']
  payment: ParsedFullInvoicesIntervention['payment']
  certificat: ParsedFullInvoicesIntervention['certificat']
  bon_intervention: ParsedFullInvoicesIntervention['bon_intervention']
  etat_des_lieux: ParsedFullInvoicesIntervention['etat_des_lieux']
  prix_total: ParsedFullInvoicesIntervention['prix_total']
  sweeper: string
  installations: {
    detail: string
    prestations: string
  }[]
}

export enum InterventionStatusEnum {
  done = 2,
}

export type FullRaf = {
  id: string
  adresse_rue?: string
  adresse_code_postal?: string
  adresse_ville?: string
  adresse_zone?: number
  adresse_cote?: string
  next_intervention_date?: string // date-time
  contacts?: string // JSON
  anomalies?: number
  prix?: string
  hasintervention?: '0' | '1'
  last_intervention_date?: string // date-time
}

export type ExtendedFullRaf = Omit<FullRaf, 'contacts' | 'prix'> & {
  contacts: ExtendedContact[]
  prix?: number
}

export type ExtendedContact = {
  accept_sms: 0 | 1
  accept_email: 0 | 1 | null
} & Omit<Contact, 'accept_sms' | 'accept_email'>
