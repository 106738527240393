import type { Theme } from '@material-ui/core'
import { Typography, Box, Toolbar, Paper } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { makeStyles } from '@material-ui/core/styles'
import type { FullContactAdresse, Contact } from '@willig/types/api'
import React, { useMemo, useState } from 'react'
import type { EditProps, Record } from 'react-admin'
import {
  Edit,
  FormWithRedirect,
  SaveButton,
  DeleteWithConfirmButton,
  useRedirect,
  useNotify,
} from 'react-admin'

import { MessagesHistory } from 'src/page/ResteAFaire/MessagesHistory'

import { InterventionList } from '../../components/InterventionList'
import { useGetList } from '../../libs/useGetList'
import { ExportData } from '../pro/ExportData'

import { validateForm } from './Create'
import { CreateEditInputs } from './CreateEditInputs'

const useStyles = makeStyles((theme: Theme) => {
  return {
    address: {
      margin: `${theme.spacing(2)}px 0`,
      display: 'flex',
      '& h4': { flexGrow: 1 },
      gap: `${theme.spacing(2)}px`,
    },
    deletedCard: {
      margin: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
  }
})

export const EditView = (props: EditProps) => {
  const { deletedCard } = useStyles()
  const notify = useNotify()
  return (
    <Edit {...props} undoable={false}>
      <FormWithRedirect
        {...props}
        validate={validateForm}
        render={(renderProps) => {
          const { saving, handleSubmit, record } = renderProps
          return record?.deleted ? (
            <Paper>
              <Card>
                <CardContent className={deletedCard}>
                  <Typography variant={'h5'}>Contact effacé</Typography>
                  <Typography variant={'body2'}>
                    Ce contact a été effacé et n'est plus accessible depuis le
                    back-office
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          ) : (
            <>
              <CreateEditInputs edit />
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    saving={saving}
                    handleSubmitWithRedirect={() => handleSubmit()}
                    onSuccess={() =>
                      notify(
                        'Changements enregistrés',
                        'success',
                        {},
                        undefined,
                        2000,
                      )
                    }
                  />

                  <ExportData
                    id={record?.id}
                    label={'Exporter les adresses'}
                    target="contact"
                  />

                  <DeleteWithConfirmButton
                    undoable={false}
                    confirmTitle="Suppression du contact"
                    confirmContent="Voulez-vous vraiment supprimer ce contact ?"
                    record={record as Contact}
                  />
                </Box>
              </Toolbar>
              <Box mt={8}>
                <MessagesHistory contactId={(record?.id as string) ?? ''} />
              </Box>
              <Box mt={8}>
                {record && <ContactInfos record={record as Contact} />}
              </Box>
            </>
          )
        }}
      />
    </Edit>
  )
}

type ContactInfoProps = {
  record: Record
}

const ContactInfos = (props: ContactInfoProps) => {
  const { record } = props
  const { address } = useStyles()
  const redirect = useRedirect()
  const { data: contactAdresses } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: { contact_id: record.id },
      pagination: { page: 1, perPage: 1000 },
    },
    { enabled: Boolean(record) },
  )
  const goToIntervention = (contact: FullContactAdresse) => {
    redirect(
      `/Intervention/create?adress_id=${contact.adresse_id}&contact_id=${contact.contact_id}`,
    )
  }
  const goToAddress = (contact: FullContactAdresse) => {
    redirect(`/Adress/${contact.adresse_id}`)
  }

  const [rowsPerPage] = useState(25)
  const [page, setPage] = useState(0)

  const handleChangePage = (event: any, direction: string) => {
    const newPage = direction === 'next' ? page + 1 : Math.max(page - 1, 0)
    setPage(newPage)
  }

  const displayedData = useMemo(() => {
    const firstIndex = page * rowsPerPage
    return contactAdresses.slice(firstIndex, firstIndex + rowsPerPage)
  }, [contactAdresses, rowsPerPage, page])

  return (
    <>
      {displayedData.map((contactAdresse) => (
        <Card key={contactAdresse.id}>
          <CardContent>
            <Typography variant={'overline'}>{'Adresse'}</Typography>
            <div className={address}>
              <Typography
                variant={'h4'}
              >{`${contactAdresse.rue} ${contactAdresse.code_postal} ${contactAdresse.ville} - ${contactAdresse.type_relation}`}</Typography>
              <MuiButton
                color="primary"
                variant="contained"
                size="small"
                onClick={() => goToAddress(contactAdresse)}
              >
                Voir l'adresse
              </MuiButton>
              <MuiButton
                color="secondary"
                variant="contained"
                size="small"
                onClick={() => goToIntervention(contactAdresse)}
              >
                Ajouter une intervention
              </MuiButton>
            </div>

            <InterventionList adresse_id={contactAdresse.adresse_id} />
          </CardContent>
        </Card>
      ))}
      <Box mt={4}>
        <Card style={{ display: 'flex', justifyContent: 'space-around' }}>
          <MuiButton
            color="primary"
            disabled={page === 0}
            onClick={(event) => handleChangePage(event, 'prev')}
          >
            Page précédente
          </MuiButton>
          <MuiButton
            color="primary"
            disabled={(page + 1) * rowsPerPage >= contactAdresses.length}
            onClick={(event) => handleChangePage(event, 'next')}
          >
            Page suivante
          </MuiButton>
        </Card>
      </Box>
    </>
  )
}
