import React from 'react'
import type { CreateProps } from 'react-admin'
import { SimpleForm, Create } from 'react-admin'

import { CreateEditInputs } from './CreateEditInputs'

export const CreateView = (props: CreateProps) => {
  const redirect = () => `/UserExtranet`

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} validate={validateUserCreation}>
        <CreateEditInputs />
      </SimpleForm>
    </Create>
  )
}

function validateUserCreation(values: any) {
  const errors: any = {}
  if (!values.pro_ids) {
    errors.pro_ids = 'Veuillez sélectionner au moins un pro'
  }
  if (!values.firstname) {
    errors.firstname = 'Veuillez renseigner un nom'
  }
  if (!values.lastname) {
    errors.lastname = 'Veuillez renseigner un prénom'
  }
  if (!values.email) {
    errors.email = 'Veuillez renseigner un email'
  }
  if (!values.is_pro) {
    errors.is_pro = 'Veuillez sélectionner une valeur'
  }
  return errors
}
