import type { Record, GetOneParams, GetOneResult } from 'react-admin'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

type QueryOptions<T> = Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'>

export function useOne<T extends Record = Record>(
  resourceName: string,
  options: GetOneParams,
  reactQueryOptions?: QueryOptions<GetOneResult<T>>,
) {
  const dataProvider = useDataProvider()
  return useQuery(
    ['getOne', resourceName, options],
    () => dataProvider.getOne<T>(resourceName, options),
    reactQueryOptions as any,
  )
}
