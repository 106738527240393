import type {
  PaginationPayload,
  SortPayload,
  UseDataProviderOptions,
  Record,
  UseQueryValue,
  GetListParams,
} from 'react-admin'
import { useDataProvider, useQuery as useQueryReact } from 'react-admin'
import { useQuery } from 'react-query'

type UseGetListFinalQueryParams = {
  pagination?: PaginationPayload
  sort?: SortPayload
  filter?: object
}

const defaultPagination = { page: 1, perPage: 25 }
const defaultSort = { field: 'id', order: 'DESC' }
const defaultFilter = {}

type UseGetListResult<T> = Omit<UseQueryValue, 'data'> & {
  data: T[]
}
export const useGetList = <RecordType extends Record = Record>(
  resource: string,
  params: UseGetListFinalQueryParams,
  options?: UseDataProviderOptions,
): UseGetListResult<RecordType> => {
  const {
    pagination = defaultPagination,
    sort = defaultSort,
    filter = defaultFilter,
  } = params
  const result = useQueryReact(
    {
      type: 'getList',
      resource,
      payload: { pagination, sort, filter },
    },
    options,
  )
  return { ...result, data: result.data || [] }
}

export function useList<T extends Record = Record>(
  resourceName: string,
  options: GetListParams,
  reactQueryOptions?: {},
) {
  const dataProvider = useDataProvider()
  return useQuery(
    ['getList', resourceName, options],
    () => dataProvider.getList<T>(resourceName, options),
    reactQueryOptions,
  )
}
