import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import { BooleanInput, SelectInput, TextInput } from 'react-admin'

export const CreateEditInputs = () => {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="code" />
            <TextInput fullWidth source="label" />
            <SelectInput
              fullWidth
              source="family"
              choices={[
                { id: 'liquide', name: 'liquide' },
                { id: 'gazeux', name: 'gazeux' },
                { id: 'solide', name: 'solide' },
                { id: 'pellet', name: 'pellet' },
              ]}
            />
          </Box>

          <BooleanInput label="Dans la liste rapide" source="in_shortlist" />
        </CardContent>
      </Card>
    </Box>
  )
}
