import type { Adress, Contact } from '@willig/types/api'
import type { FC } from 'react'
import React from 'react'
import type { FilterProps, ListProps } from 'react-admin'
import {
  BulkExportButton,
  Datagrid,
  DateField,
  DateInput,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  FunctionField,
  AutocompleteInput,
  Filter,
} from 'react-admin'

import { DefaultPagination } from '../../components/defaultPagination'
import { concatNames, concatAddress } from '../../libs/concatNames'

export const RessourceList = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={<InterventionFilters />}
      bulkActionButtons={<BulkExportButton />}
      pagination={<DefaultPagination />}
      perPage={50}
    >
      <Datagrid rowClick="edit">
        <DateField source="rdv_date" label="Date" />
        <TextField source="rdv_time" label="Heure" />
        <ReferenceField source="adresse_id" reference="Adress">
          <FunctionField<Adress> render={(record) => concatAddress(record)} />
        </ReferenceField>

        <ReferenceField source="contact_id" reference="Contact" label="Contact">
          <FunctionField<Contact>
            render={(record) => {
              const fullName = concatNames(record)
              return fullName
            }}
          />
        </ReferenceField>

        <ReferenceField source="status" reference="StatusIntervention">
          <TextField source="label" />
        </ReferenceField>

        <ReferenceField source="sweep_id" reference="User" label="Ramoneur">
          <TextField source="login" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}

const InterventionFilters: FC<Partial<FilterProps>> = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="adresse_id"
        reference="Adress"
        filterToQuery={(searchText) => {
          return { rue: searchText }
        }}
        alwaysOn
      >
        <AutocompleteInput
          optionText={(adresse: Adress): string => {
            return `${adresse?.rue || ''} ${adresse?.code_postal || ''} ${
              adresse?.ville || ''
            }`
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        source="contact_id"
        reference="Contact"
        filterToQuery={(searchText) => {
          return { nom: searchText }
        }}
        alwaysOn
      >
        <AutocompleteInput
          optionText={(contact: Contact): string => {
            return `${contact?.nom || ''} ${contact?.prenom || ''}`
          }}
        />
      </ReferenceInput>
      <ReferenceInput source="status" reference="StatusIntervention" alwaysOn>
        <SelectInput optionText="label" />
      </ReferenceInput>
      <ReferenceInput
        source="sweep_id"
        reference="User"
        filter={{ '<access_level': [50], '>access_level': [9] }}
        sort={{ field: 'access_level', order: 'DESC' }}
        alwaysOn
        label="Ramoneur"
      >
        <SelectInput optionText="login" />
      </ReferenceInput>
      <DateInput source="rdv_date" alwaysOn />
    </Filter>
  )
}
