import type { User } from '@willig/types/api'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

export function useSweeper(id: string, options?: UseQueryOptions) {
  const dataProvider = useDataProvider()
  const { data: sweeper } = useQuery<User>(
    ['User', id],
    async () => {
      const { data } = await dataProvider.getOne('User', { id })
      return data as User
    },
    { staleTime: Infinity, ...(options as any) },
  )

  return sweeper
}
