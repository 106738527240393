import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import {
  EnumCatalogType,
  EnumCatalogMode,
  EnumCatalogGas,
  EnumCatalogExtra,
} from '@willig/types/api'
import type { FullCatalog } from '@willig/types/api'
import React, { useEffect, useState } from 'react'
import {
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'

import { useForm } from 'react-final-form'

export const CreateEditInputs = (props: any) => {
  const { record }: { record: FullCatalog } = props
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box>
            <SelectInput
              fullWidth
              source="type"
              choices={Object.entries(EnumCatalogType).map(([name, value]) => {
                return { name: name, id: value }
              })}
            />
            <SelectInput
              fullWidth
              source="mode"
              choices={Object.entries(EnumCatalogMode).map(([name, value]) => {
                return { name: name, id: value }
              })}
            />
            <TextInput fullWidth source="name" />
            <ReferenceInput fullWidth source="appareil_id" reference="Appareil">
              <SelectInput optionText="label" />
            </ReferenceInput>
            <SelectInput
              fullWidth
              source="gas"
              choices={Object.entries(EnumCatalogGas).map(([name, value]) => {
                return { name: name, id: value }
              })}
            />
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <CatalogPrices type="price_simple" data={record.price_simple} />
          <CatalogPrices type="price_medium" data={record.price_medium} />
          <CatalogPrices type="price_hard" data={record.price_hard} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box>
            <BooleanInput source="collective" />
            <SelectInput
              fullWidth
              source="extra"
              choices={Object.entries(EnumCatalogExtra).map(([name, value]) => {
                return { name: name, id: value }
              })}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
type PriceTypeProps = {
  type: 'price_simple' | 'price_medium' | 'price_hard'
  data: number | undefined
}
const CatalogPrices = (props: PriceTypeProps) => {
  const { type, data } = props
  const form = useForm()
  const [priceHT, setPriceHT] = useState<number>(0)
  useEffect(() => {
    if (data) {
      setPriceHT(data)
    }
  }, [data])
  const onChange = (newPriceHT: number) => {
    setPriceHT(() => {
      form.change(`prices.${type}`, newPriceHT)
      return newPriceHT
    })
  }
  return (
    <Box display="flex" gridGap={8}>
      <TextField
        value={roundNumber(priceHT)}
        onChange={(event) => onChange(Number(event.target.value))}
        label={`${getTitleType(type)} HT`}
        variant="filled"
        type="number"
        margin="dense"
      />
      <TextField
        value={roundNumber(priceHT * 1.1)}
        onChange={(event) => onChange(Number(event.target.value) / 1.1)}
        label={`${getTitleType(type)} Tva 10%`}
        variant="filled"
        type="number"
        margin="dense"
      />
      <TextField
        value={roundNumber(priceHT * 1.2)}
        onChange={(event) => onChange(Number(event.target.value) / 1.2)}
        label={`${getTitleType(type)} TVA 20%`}
        variant="filled"
        type="number"
        margin="dense"
      />
    </Box>
  )
}

const roundNumber = (number: number) => {
  return Math.round(number * 100) / 100
}

function getTitleType(type: string) {
  if (type === 'price_simple') {
    return 'prix normal'
  }
  if (type === 'price_medium') {
    return 'prix classique'
  }
  return 'prix complexe'
}
