import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  TableCell,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import type { Contact, Adress } from '@willig/types/api'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import xlsx from 'json-as-xlsx'

import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'
import { useNotify, Link } from 'react-admin'

import { concatNames, concatAddress } from 'src/libs/concatNames'

import { useOne } from 'src/libs/useOne'

import { useNxHttpClient } from '../../adapters/nxHttpClient'

import type { InvoiceFilter } from './InvoiceFilter'
import { InvoiceFilters } from './InvoiceFilter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      width: '100%',
    },
    buttonProgress: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    tableView: {
      backgroundColor: theme.palette.primary.light,
      '& th': {
        fontWeight: 600,
        color: 'white',
        fontSize: '16px',
      },
    },
  }),
)
type line = {
  count: number
  catalog_id: number
  catalog_price: number
}
type detail = {
  id: string
  checked: boolean
}

type installation = {
  id: string
  lines: line[]
  detail: detail[]
}

type DailyInvoices = {
  date_intervention?: string
  zoho_id_payeur: string
  infos_contacts?: {
    contact_id: string
    zoho_id?: string
    raison_sociale?: string
    nom?: string
    prenom?: string
  }
  facturation_adresse?: {
    adresse_id: string
    zoho_id: string
    rue: string
    code_postal: string
    ville: string
  }
  adresses_interventions?: {
    adresse_id: string
    zoho_id: string
    rue: string
    code_postal: string
    ville: string
  }
  intervention_id?: string
  intervention_report_id?: string
  price_ttc: string
  type_paiement: string
  invoice: {
    tva: number
    divers: string[]
    installations: installation[]
    price_edited: number
    price_computed: number
    intervention_id: string
  }
  paiement: {
    paiement_cash: boolean
    paiement_check: boolean
    paiement_transfert: boolean
  }
  ramoneur: string
  num_commande?: string
}

export const Invoices = () => {
  const nxHttpClient = useNxHttpClient()
  const [filter, setFilter] = useState<InvoiceFilter | null>(null)
  const [date, setDate] = useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [invoices, setInvoices] = useState<DailyInvoices[]>([])
  const [totalPrice, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const enabled = Boolean(date)
  const notify = useNotify()
  useEffect(() => {
    if (enabled) {
      setLoading(true)
      nxHttpClient
        .patch('/InterventionReport/getDailyInvoices', {
          date: date,
        })
        .then((result) => {
          let rawResult = result.data

          if (filter?.ramoneur !== undefined && filter?.ramoneur !== '') {
            rawResult = rawResult.filter((res: any) => {
              return res.ramoneur === filter.ramoneur
            })
          }
          if (
            filter?.type_paiement !== undefined &&
            filter?.type_paiement !== '' &&
            filter?.type_paiement !== 'impayé'
          ) {
            rawResult = rawResult.filter((inv: any) => {
              return inv.type_paiement === filter.type_paiement
            })
          }
          if (filter?.type_paiement === 'impayé') {
            rawResult = rawResult.filter((inv: any) => {
              return inv.type_paiement === null
            })
          }
          setLoading(false)
          setInvoices(rawResult)
          setTotal(total(rawResult))
        })
        .catch((error) => {
          if (error?.message) {
            notify(error.message, 'error')
          }
        })
    }
  }, [date, nxHttpClient, enabled, notify, filter])

  function total(items: DailyInvoices[]) {
    return items
      .map((item) => (item?.price_ttc ? parseFloat(item?.price_ttc) : 0))
      .reduce((sum: any, i: any) => sum + i, 0)
  }

  function onSubmit(filterValue: any) {
    setFilter(filterValue)
  }

  return (
    <div>
      <DatePicker setDate={setDate} />
      <InvoiceFilters onSubmit={onSubmit} />
      <ExportInvoice
        invoices={invoices}
        date={date}
        enabled={enabled}
        loading={loading}
        total={totalPrice}
      />
    </div>
  )
}

type DatePickerProps = {
  setDate: Dispatch<SetStateAction<string>>
}
const DatePicker = (props: DatePickerProps) => {
  const { setDate } = props
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setDate(event.target.value)
  }
  return (
    <Box>
      <TextField
        fullWidth
        onChange={handleChange}
        id="date"
        label="Date remplissage intervention (pas date intervention)"
        type="date"
        defaultValue={format(new Date(), 'yyyy-MM-dd')}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  )
}

type ExportToBeDoneProps = {
  invoices: any[]
  loading: boolean
  enabled: boolean
  date: string | undefined
  total: number
}

const ExportInvoice = (props: ExportToBeDoneProps) => {
  const { invoices, loading, enabled, date, total } = props
  const { buttonWrapper, buttonProgress, root, tableView } = useStyles()
  function handlePrice(price: string) {
    if (!price) return 'Non renseigné'
    return Number(price).toFixed(2).replace('.', ',')
  }
  const data = [
    {
      sheet: `Facture ${date}`,
      columns: [
        {
          label: 'Adresse intervention',
          value: (value: DailyInvoices) => {
            return `${value.adresses_interventions?.rue || ''} ${
              value.adresses_interventions?.code_postal || ''
            } ${value.adresses_interventions?.ville || ''}`
          },
        },
        {
          label: 'Adresse facturation',
          value: (value: DailyInvoices) => {
            return `${value.facturation_adresse?.rue || ''} ${
              value.facturation_adresse?.code_postal || ''
            } ${value.facturation_adresse?.ville || ''}`
          },
        },
        {
          label: 'Id Contact',
          value: (value: DailyInvoices) =>
            `${value?.infos_contacts?.contact_id || ''}`,
        },
        {
          label: 'Nom',
          value: (value: DailyInvoices) =>
            `${value?.infos_contacts?.nom || ''}`,
        },
        {
          label: 'Prénom',
          value: (value: DailyInvoices) =>
            `${value?.infos_contacts?.prenom || ''}`,
        },
        {
          label: 'Raison sociale',
          value: (value: DailyInvoices) =>
            `${value?.infos_contacts?.raison_sociale || ''}`,
        },
        { label: 'Date et Heure RDV', value: 'date_intervention' },
        { label: 'Ramoneur', value: 'ramoneur' },
        {
          label: 'Moyen paiement',
          value: (value: DailyInvoices) => {
            return value?.type_paiement || 'non renseigné'
          },
        },
        {
          label: 'Prix TTC',
          value: (value: DailyInvoices) => {
            return value?.price_ttc ? parseFloat(value?.price_ttc) : 0
          },
        },
        {
          label: 'id adresse',
          value: (value: DailyInvoices) => {
            return `${value.adresses_interventions?.adresse_id || ''}`
          },
        },
        {
          label: 'id zoho adresse intervention',
          value: (value: DailyInvoices) => {
            return `${value.adresses_interventions?.zoho_id || ''}`
          },
        },
        {
          label: 'id adresse facturation',
          value: (value: DailyInvoices) => {
            return `${value.facturation_adresse?.adresse_id || ''}`
          },
        },
        {
          label: 'id zoho adresse facturation',
          value: (value: DailyInvoices) => {
            return `${value.facturation_adresse?.zoho_id || ''}`
          },
        },
        {
          label: 'Prestation effectuées',
          value: (value: DailyInvoices) => {
            return JSON.stringify(value?.invoice || '')
          },
        },
        {
          label: 'Paiement (Prix edité ou calculé)',
          value: (value: DailyInvoices) => {
            return !value.invoice
              ? 0
              : value.invoice.price_edited !== undefined
              ? value.invoice.price_edited
              : value.invoice.price_computed
          },
        },
        {
          label: 'Id zoho payeur',
          value: (value: DailyInvoices) => `${value?.zoho_id_payeur || ''}`,
        },
        {
          label: 'Id intervention',
          value: (value: DailyInvoices) => `${value?.intervention_id || ''}`,
        },
        {
          label: "Id rapport d'intervention",
          value: (value: DailyInvoices) =>
            `${value?.intervention_report_id || ''}`,
        },
        {
          label: 'Numéro de commande',
          value: (value: DailyInvoices) => value.num_commande ?? '',
        },
      ],
      content: invoices,
    },
  ]
  const exportInvoices = () => {
    const settings = {
      fileName: `Facture ${date}`, // Name of the spreadsheet
      extraLength: 50, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }
    xlsx(data, settings)
  }
  return (
    <>
      <div className={root}>
        <div className={buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !enabled}
            onClick={exportInvoices}
          >
            Exporter
          </Button>
          {loading && <CircularProgress size={24} className={buttonProgress} />}
        </div>
      </div>
      {invoices.length > 0 && (
        <>
          <Typography variant="h3" style={{ margin: '20px 0' }}>
            Visualisation avant export
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className={tableView}>
                  <TableCell>Payeur</TableCell>
                  <TableCell>Payeur (Zoho id)</TableCell>
                  <TableCell>Adresse d'intervention</TableCell>
                  <TableCell>Date d'intervention</TableCell>
                  <TableCell>Ramoneur</TableCell>
                  <TableCell>Paiement</TableCell>
                  <TableCell align="right">Prix (TTC)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((inv, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {concatNames(inv?.infos_contacts as Contact)}
                    </TableCell>
                    <TableCell>{inv.zoho_id_payeur}</TableCell>
                    <TableCell>
                      <AdressName
                        id={inv?.adresses_interventions?.adresse_id}
                      />
                    </TableCell>
                    <TableCell>
                      {format(new Date(inv.date_intervention), 'dd MMMM yyyy', {
                        locale: fr,
                      })}
                    </TableCell>
                    <TableCell>{inv.ramoneur}</TableCell>
                    <TableCell>{inv.type_paiement}</TableCell>
                    <TableCell align="right">
                      {handlePrice(inv?.price_ttc)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer style={{ marginTop: '10px' }}>
            <Table>
              <TableHead>
                <TableRow className={tableView}>
                  <TableCell variant="head">Total (TTC)</TableCell>
                  <TableCell align="right">{`${total.toFixed(2)} €`}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

function AdressName(props: { id?: string }) {
  const { id } = props
  const { data: adressName, isLoading } = useOne<Adress>(
    'Adress',
    {
      id: id ?? 'null',
    },
    { enabled: Boolean(id) },
  )

  if (isLoading) return <CircularProgress size={24} />

  return (
    <Link to={`/adress/${adressName?.data.id}`} target="_blank">
      {concatAddress({
        rue: adressName?.data.rue,
        code_postal: adressName?.data.code_postal,
        ville: adressName?.data.ville,
        cote: adressName?.data.cote,
      } as Adress)}
    </Link>
  )
}
