import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import { ReferenceInput, required, SelectInput, TextInput } from 'react-admin'

export const CreateEditInputs = () => {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="code" />
            <TextInput fullWidth source="label" />
          </Box>
          <Box>
            <ReferenceInput
              fullWidth
              label="Zone"
              source="zone_id"
              reference="Zone"
              validate={[required()]}
            >
              <SelectInput optionText="label" />
            </ReferenceInput>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
