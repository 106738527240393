import {
  Card,
  Typography,
  makeStyles,
  Button,
  Modal,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Box,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { fromUnixTime } from 'date-fns'

import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { useEnv } from 'src/adapters/env'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'

const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      maxHeight: theme.spacing(20),
      overflow: 'scroll',
    },
    historicHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(4),
    },
    historicModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      minWidth: theme.spacing(70),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }
})

type HistoryMessageProps = {
  contactId: string
}

export function MessagesHistory(props: HistoryMessageProps) {
  const { contactId } = props
  const { data: contactHistory, isLoading } = useHistory(contactId)
  const style = useStyles()

  if (isLoading) return <>Chargement ...</>

  return (
    <Box mt={2} display="flex" gridGap={16} flexDirection="column">
      <div className={style.historicHeader}>
        <Typography variant="overline">Historique des échanges</Typography>
        <HistoryModal contactId={contactId} />
      </div>

      <Card className={style.card}>
        {!contactHistory?.length && <p>Aucun historique</p>}
        {contactHistory?.map((history: History) => {
          const date = dateFormatter(history.create_dt)

          return (
            <p
              key={history.id}
            >{`${date} : ${history.subject} (${history.type})`}</p>
          )
        })}
      </Card>
    </Box>
  )
}
type History = {
  id: any
  type: 'repondeur' | 'appel' | 'courrier' | 'relance'
  subject: string
  current_state: 'sent' | 'duplicate' | 'delivered'
  create_dt: any
  change_dt: any
  sib_id: string
}

function useHistory(contactId: string) {
  const env = useEnv()
  const jwt = localStorage.getItem('jwt')
  const nxHttpClient = useNxHttpClient()

  return useQuery<History[]>(['SIB', 'getHistory', contactId], async () => {
    const sibUrl = env.SIB_URL
    const response = await nxHttpClient.get(
      `${sibUrl}/history?id=${contactId}`,
      {
        headers: {
          jwt,
          'x-api-key': env.SIB_API_KEY,
          'Content-Type': 'application/json',
        },
      },
    )
    return response.data
  })
}

function dateFormatter(date: number) {
  return new Date(fromUnixTime(date)).toLocaleDateString('fr', {
    dateStyle: 'long',
  })
}

type TouchProps = {
  to: string
  subject: string
  type: 'repondeur' | 'appel' | 'courrier' | 'relance'
}

export function HistoryModal(props: HistoryMessageProps) {
  const { contactId } = props
  const queryClient = useQueryClient()
  const env = useEnv()
  const [open, setOpen] = useState<boolean>(false)
  const style = useStyles()
  const nxHttpClient = useNxHttpClient()
  const jwt = localStorage.getItem('jwt')

  const { handleSubmit, control, reset } = useForm<TouchProps>({
    defaultValues: {
      to: contactId,
      type: 'appel',
      subject: '',
    },
  })
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  function onSubmit(data: TouchProps) {
    const { to, type, subject } = data
    const content = {
      content: {
        to,
        type,
        subject,
        body: subject,
      },
    }
    nxHttpClient
      .patch(`${env.API_URL}/Contact/touch`, content, {
        headers: {
          jwt,
          'x-api-key': env.SIB_API_KEY,
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        queryClient.invalidateQueries(['SIB', 'getHistory', contactId])
        reset()
        handleClose()
      })
  }
  return (
    <div>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleOpen}
      >
        Ajouter un échange
      </Button>
      <Modal open={open} onClose={handleClose} className={style.historicModal}>
        <form className={style.modalContent} onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h5">Ajouter un échange</Typography>
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={(renderProps) => {
              const { field } = renderProps
              return (
                <>
                  <InputLabel id="messageType">Type de message</InputLabel>
                  <Select labelId="messageType" {...field}>
                    <MenuItem value={'appel'}>Appel</MenuItem>
                    <MenuItem value={'repondeur'}>Répondeur</MenuItem>
                    <MenuItem value={'courrier'}>Courrier</MenuItem>
                    <MenuItem value={'relance'}>Relance</MenuItem>
                  </Select>
                </>
              )
            }}
          />
          <Controller
            name="subject"
            control={control}
            rules={{ required: true }}
            render={(renderProps) => {
              const { field } = renderProps
              return (
                <>
                  <InputLabel id="messageType">Contenu du message</InputLabel>
                  <Input multiline {...field} />
                </>
              )
            }}
          />
          <Button type="submit">Ajouter</Button>
        </form>
      </Modal>
    </div>
  )
}
