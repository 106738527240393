import type { Theme } from '@material-ui/core'
import { Box, Toolbar, Typography, Modal } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  DeleteForever as DeleteForeverIcon,
  Mail as MailIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { EnumFullContactAdresseTypeRelation } from '@willig/types/api'
import type {
  EnumContactsAdressType,
  Adress,
  ContactsAdress,
  FullContactAdresse,
  FullAddress,
} from '@willig/types/api'
import { useState } from 'react'
import {
  Edit,
  FormWithRedirect,
  SaveButton,
  useRedirect,
  useUpdate,
  useNotify,
  Button,
} from 'react-admin'
import type { EditProps, Identifier } from 'react-admin'
import { SelectInput } from 'src/components/SelectInput'
import { convertEnumAsOptionWithValue } from 'src/libs/enumAsOptions'

import { MessagesHistory } from 'src/page/ResteAFaire/MessagesHistory'

import { InterventionList } from '../../components/InterventionList'
import { useGetList } from '../../libs/useGetList'

import { AddRelationshipModal } from './AddRelationShipModal'
import { AnomaliesList } from './AnomaliesList'
import { CreateEditInputs } from './CreateEditInputs'

const useStyles = makeStyles((theme: Theme) => {
  return {
    deleteButton: { color: theme.palette.error.main },
    historyModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      minWidth: theme.spacing(50),
      maxWidth: '30%',
      maxHeight: '80%',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  }
})

type ParsedAddress = FullAddress & { anomalies: string[] }

type AnomaliesInfoProps = {
  record: ParsedAddress
}

export const EditView = (props: EditProps) => {
  const notify = useNotify()
  return (
    <Edit {...props} undoable={false} resource="Adress">
      <FormWithRedirect
        {...props}
        redirect="edit"
        render={(renderProps) => {
          const { saving, handleSubmit, record } = renderProps
          return (
            <>
              <CreateEditInputs record={record as Adress} />
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    saving={saving}
                    handleSubmitWithRedirect={handleSubmit}
                    onSuccess={() =>
                      notify(
                        'Changements enregistrés',
                        'success',
                        {},
                        undefined,
                        2000,
                      )
                    }
                  />
                </Box>
              </Toolbar>

              <Box mt={8}>
                {record && <AddressInfos record={record as ParsedAddress} />}
              </Box>
            </>
          )
        }}
      />
    </Edit>
  )
}

const AddressInfos = (props: AnomaliesInfoProps) => {
  const { record } = props
  const { deleteButton } = useStyles()

  const redirect = useRedirect()
  const { data: contactAdresses, refetch } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    { filter: { adresse_id: record.id } },
    { enabled: Boolean(record) },
  )
  const goToContact = (contact: FullContactAdresse) => {
    redirect(`/Contact/${contact.contact_id}`)
  }
  const notify = useNotify()
  const [update] = useUpdate<ContactsAdress>()
  const handleRelationTypeChange = (
    id: number,
    value: EnumFullContactAdresseTypeRelation,
  ) => {
    update('ContactsAdress', id, {
      type: value as unknown as EnumContactsAdressType,
    })
    notify('Relation mise à jour', 'success')
  }
  const deleteContactAddress = (id: number) => {
    if (
      window.confirm(
        `La relation ${id} est sur le point d'être supprimée, continuer ? `,
      )
    ) {
      update(
        'ContactsAdress',
        id,
        { deleted: true },
        {},
        {
          onSuccess() {
            notify('Relation suprimée', 'success')
            refetch()
          },
        },
      )
    }
  }
  const goToIntervention = (adress_id: Identifier | undefined) => {
    redirect(`/Intervention/create?adress_id=${adress_id}`)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
      <MuiButton
        fullWidth
        color="secondary"
        variant="contained"
        size="small"
        onClick={() => goToIntervention(record?.id)}
      >
        Ajouter une intervention
      </MuiButton>
      <Card>
        <CardContent>
          <InterventionList adresse_id={record.id ?? ''} />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <AnomaliesInfo
            record={record as FullAddress & { anomalies: string[] }}
          />
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            marginBottom={2}
          >
            <Typography variant={'overline'}>{'Contacts'}</Typography>
            <AddRelationshipModal
              adresse_id={record.id as string}
              refetch={refetch}
            />
          </Box>

          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell width={'12%'} align="left">
                    Contact
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Relation
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Raison sociale
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Nom
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Prenom
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Fixe
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Mobile
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Email
                  </TableCell>
                  <TableCell width={'12%'} align="left">
                    Payeur
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contactAdresses &&
                  contactAdresses.map((contactAdresse) => (
                    <TableRow key={contactAdresse.id}>
                      <TableCell width={'12%'} align="left">
                        <MuiButton
                          onClick={() => goToContact(contactAdresse)}
                          color="primary"
                          variant="contained"
                          size="small"
                        >
                          Voir le contact
                        </MuiButton>
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        <Box display={'flex'} gridGap={8}>
                          <SelectInput<EnumFullContactAdresseTypeRelation>
                            label={'Relation'}
                            initialState={contactAdresse.type_relation}
                            choices={convertEnumAsOptionWithValue(
                              EnumFullContactAdresseTypeRelation,
                            )}
                            onChange={(value) =>
                              handleRelationTypeChange(
                                contactAdresse.id,
                                value as EnumFullContactAdresseTypeRelation,
                              )
                            }
                          />
                        </Box>
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.raison_sociale}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.nom}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.prenom}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.fixe}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.mobile}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.email}
                      </TableCell>
                      <TableCell width={'12%'} align="left">
                        {contactAdresse.is_payor ? (
                          <CheckIcon />
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>

                      <TableCell width={'12%'} align="left">
                        <Button
                          onClick={() =>
                            deleteContactAddress(contactAdresse.id)
                          }
                          className={deleteButton}
                        >
                          <>
                            <DeleteForeverIcon /> Supprimer
                          </>
                        </Button>
                        <ModalHistory contactId={contactAdresse.contact_id} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </Box>
  )
}

type ModalHistoryProps = {
  contactId: string
}

function ModalHistory(props: ModalHistoryProps) {
  const { contactId } = props
  const { historyModal, modal } = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  function openModal() {
    setIsOpen(true)
  }
  function handleClose() {
    setIsOpen(false)
  }
  return (
    <div>
      <Button onClick={() => openModal()}>
        <>
          <MailIcon /> Historique
        </>
      </Button>
      <Modal open={isOpen} onClose={handleClose} className={historyModal}>
        <div className={modal}>
          <MessagesHistory contactId={contactId} />
        </div>
      </Modal>
    </div>
  )
}

function AnomaliesInfo(props: AnomaliesInfoProps) {
  const { record } = props
  return (
    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
      <AnomaliesList address={record} />
    </Box>
  )
}
