import { useCallback } from 'react'
import { required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import AdresseReferenceInput from './AdresseReferenceInput'
import { SuggestedAdresses } from './SuggestedAdresses'

type ResidentContactProps = {
  searchFacture: any
  setSearchFacture: any
  state: any
}

export const BillingAdress = (props: ResidentContactProps) => {
  const { searchFacture, setSearchFacture, state } = props
  const form = useForm() // form used to change values
  const { values } = useFormState({ subscription: { values: true } }) // current values of every field in the form

  const callback = useCallback(
    (choice: string | null) => {
      if (choice) {
        form.change('facture_adresse_id', choice)
      }
      setSearchFacture(true)
    },
    [form, setSearchFacture],
  )
  const filterToQuery = useCallback((searchText: string) => {
    return {
      rue: searchText,
    }
  }, [])
  return (
    <>
      {values.facture_contact_id &&
        !values.facture_adresse_id &&
        !searchFacture && (
          <SuggestedAdresses
            state={state}
            facture_contact_id={values.facture_contact_id as string}
            cb={callback}
          />
        )}
      {searchFacture && (
        <>
          <AdresseReferenceInput
            inputLabel="Adresse de facturation"
            source="facture_adresse_id"
            reference="Adress"
            validate={required()}
            perPage={10000}
            target={'facture_adresse_id'}
            filterToQuery={filterToQuery}
            emptyAdresseInput={['facture_adresse_id']}
          />
        </>
      )}
    </>
  )
}
