import { House as HouseIcon } from '@material-ui/icons'

import { CreateView } from './Create'
import { EditView } from './Edit'
import { ListView } from './List'

// eslint-disable-next-line import/no-anonymous-default-export
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: CreateView,
  edit: EditView,
  list: ListView,
  icon: HouseIcon,
}
