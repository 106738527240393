import { Button } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import type { Theme } from '@material-ui/core/styles'
import type { User } from '@willig/types/api'
import React from 'react'
import { useNotify } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'
import { Picker } from 'src/components/Form'
import { useList } from 'src/libs/useGetList'

export type InvoiceFilter = {
  ramoneur?: string
  type_paiement?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '32px',
      margin: theme.spacing(1),
    },
    button: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
)

type InvoiceFilterProps = {
  onSubmit: (filter: InvoiceFilter) => void
}

export function InvoiceFilters(props: InvoiceFilterProps) {
  const { onSubmit } = props
  const notify = useNotify()
  const { data: sweep } = useList<User>('User', {
    pagination: { page: 1, perPage: 1000 },
    filter: { '<access_level': [50], '>access_level': [9] },
    sort: { field: 'access_level', order: 'DESC' },
  })

  const methods = useForm<InvoiceFilter>({
    defaultValues: {
      ramoneur: '',
      type_paiement: '',
    },
  })

  const { handleSubmit } = methods
  const style = useStyles()

  return (
    <div>
      <FormProvider {...methods}>
        <form
          className={style.root}
          onSubmit={handleSubmit(onSubmit, (error) => {
            Object.values(error).forEach((err) => {
              return notify(`${err}`, 'warning', {}, false, 1500)
            })
          })}
        >
          <Picker
            options={
              sweep?.data.map((sweepName) => {
                return {
                  value: sweepName.login,
                  label: sweepName.login,
                }
              }) ?? []
            }
            name="ramoneur"
            label="Ramoneur"
          />
          <Picker
            options={[
              {
                value: 'cheque',
                label: 'Chèque',
              },
              {
                value: 'especes',
                label: 'Espèce',
              },
              {
                value: 'CB',
                label: 'Carte bancaire',
              },
              {
                value: 'impayé',
                label: 'Sans paiement',
              },
            ]}
            name="type_paiement"
            label="Paiement"
          />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={style.button}
          >
            Filtrer
          </Button>
        </form>
      </FormProvider>
    </div>
  )
}
