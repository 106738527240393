import type { Theme } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { InterventionList } from 'src/components/InterventionList'

type InterventionHistoryModalProps = {
  toggleModal: Dispatch<SetStateAction<boolean>>
  modalState: boolean
  adresse_id: string
}

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '90%',
    overflow: 'scroll',
  }
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      position: 'absolute',
      width: 800,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(2)}px`,
    },
  }
})
export function InterventionHistoryModal(props: InterventionHistoryModalProps) {
  const { toggleModal, modalState, adresse_id } = props
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const handleClose = () => {
    toggleModal(false)
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalState}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <InterventionList adresse_id={adresse_id} />
      </div>
    </Modal>
  )
}
