import type { Adress, Contact } from '@willig/types/api'
import { useGetOne } from 'react-admin'
import { useFormState } from 'react-final-form'

import type { IndexName } from './TypesenseInput'
import { TypesenseInput } from './TypesenseInput'

type TypesenseWrapperProps = {
  source: string
  indexName: IndexName
  inputLabel: string
  resource: string
  emptyInput: string[]
}
export const TypesenseWrapper = (props: TypesenseWrapperProps) => {
  const { resource, source, indexName, inputLabel, emptyInput } = props
  const { values } = useFormState({ subscription: { values: true } })
  const { data } = useGetOne<Contact | Adress>(resource, values[source], {
    enabled: Boolean(values[source]),
  })
  return (
    <TypesenseInput
      source={source}
      indexName={indexName}
      inputLabel={inputLabel}
      data={data}
      emptyInputArray={emptyInput}
    />
  )
}
