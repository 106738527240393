import {
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Collapse,
  Typography,
  makeStyles,
  Box,
  Chip,
  Grid,
  Button,
  TableSortLabel,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import type { Contact, Adress } from '@willig/types/api'
import classNames from 'classnames'
import { compareAsc, format, compareDesc } from 'date-fns'
import { fr } from 'date-fns/locale'
import type { PropsWithChildren } from 'react'
import { useState, useMemo } from 'react'
import { Link } from 'react-admin'
import { InterventionList } from 'src/components/InterventionList'
import { capitalize } from 'src/libs/capitalize'

import { concatNames, concatAddress } from 'src/libs/concatNames'
import type {
  ExtendedFullRaf,
  ExtendedContact,
} from 'src/types/api/extendedTypes'
import { button } from 'src/UI/theme/mixins'

import { MessagesHistory } from './MessagesHistory'

import type { AddressesSelectedState, AddressSelectedState } from '.'

type ToBeDoneListProps = {
  isLoading?: boolean
  data: ExtendedFullRaf[]
  addressesSelectedStateStore: ProfunctorState<AddressesSelectedState>
}

const useTableStyles = makeStyles(function () {
  return {
    row: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  }
})

export function ToBeDoneList(props: ToBeDoneListProps) {
  const { data, isLoading, addressesSelectedStateStore } = props
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [page, setPage] = useState(0)
  const [sortAscNext, setSortAscNext] = useState<boolean | undefined>(true)
  const [sortAscLast, setSortAscLast] = useState<boolean | undefined>(undefined)
  const [filterField, setFilterField] = useState('next_intervention_date')

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function dateSort(a: any, b: any, order: string, field: string) {
    if (order === 'asc') {
      return compareAsc(
        new Date(a[field]).getTime(),
        new Date(b[field]).getTime(),
      )
    }
    return compareDesc(
      new Date(a[field]).getTime(),
      new Date(b[field]).getTime(),
    )
  }

  const displayedData = useMemo(() => {
    const firstIndex = page * rowsPerPage
    function getComparator(
      isSortAsc: boolean | undefined,
      filteredField: string,
    ): (a: any, b: any) => any {
      return isSortAsc === true
        ? (a, b) => dateSort(a, b, 'asc', filteredField)
        : (a, b) => dateSort(a, b, 'desc', filteredField)
    }
    if (sortAscNext === undefined) {
      return data
        .sort(getComparator(sortAscLast, filterField))
        .slice(firstIndex, firstIndex + rowsPerPage)
    }
    if (sortAscLast === undefined) {
      return data
        .sort(getComparator(sortAscNext, filterField))
        .slice(firstIndex, firstIndex + rowsPerPage)
    }
  }, [data, rowsPerPage, page, sortAscNext, filterField, sortAscLast])

  const styles = useTableStyles()

  function selectAllAddress() {
    addressesSelectedStateStore.setState((prevSelected) => {
      const result: AddressesSelectedState = {}
      displayedData?.forEach((raf) => {
        if (prevSelected[raf.id]) {
          return (result[raf.id] = { ...prevSelected[raf.id], selected: true })
        }

        result[raf.id] = {
          selected: true,
          contact: raf.contacts[0],
        }
      })

      return { ...prevSelected, ...result }
    })
  }

  function unselectAllAddress() {
    addressesSelectedStateStore.setState((prevSelected) => {
      const result: AddressesSelectedState = {}
      Object.entries(prevSelected).forEach(([addressId, selectState]) => {
        if (
          displayedData &&
          displayedData.find((raf) => raf.id === addressId)
        ) {
          result[addressId] = { ...selectState, selected: false }
        }
      })

      return { ...prevSelected, ...result }
    })
  }

  function handleSortField(
    field: 'last_intervention_date' | 'next_intervention_date',
  ) {
    setFilterField(field)
    if (field === 'last_intervention_date') {
      setSortAscLast(!sortAscLast)
      setSortAscNext(undefined)
    } else {
      setSortAscNext(!sortAscNext)
      setSortAscLast(undefined)
    }
  }

  const isAllSelected = useMemo(() => {
    if (!displayedData || displayedData.length === 0) return false
    return displayedData.every((raf) => {
      return addressesSelectedStateStore.state[raf.id]?.selected
    })
  }, [displayedData, addressesSelectedStateStore])

  return (
    <>
      <Typography variant="h5">Résultats</Typography>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={styles.row}>
              <TableCell>
                <Checkbox
                  checked={isAllSelected}
                  onChange={() => {
                    if (!isAllSelected) return selectAllAddress()
                    unselectAllAddress()
                  }}
                />
              </TableCell>
              <TableHeader>Contact</TableHeader>
              <TableHeader>Adresse</TableHeader>
              <TableCell>
                <TableSortLabel
                  active
                  direction={sortAscNext ? 'desc' : 'asc'}
                  onClick={() => handleSortField('next_intervention_date')}
                >
                  <Typography variant="h6">date d'échéance </Typography>
                </TableSortLabel>
              </TableCell>
              <TableHeader>Prix dernière int. (TTC)</TableHeader>
              <TableCell>
                <TableSortLabel
                  active
                  direction={sortAscLast ? 'desc' : 'asc'}
                  onClick={() => handleSortField('last_intervention_date')}
                >
                  <Typography variant="h6">Dernière intervention</Typography>
                </TableSortLabel>
              </TableCell>
              <TableHeader>Statut</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Typography>Chargement</Typography>
            ) : data.length === 0 ? (
              <Typography>Aucune données</Typography>
            ) : (
              displayedData?.map((raf) => (
                <RafRow
                  raf={raf}
                  key={raf.id}
                  addressesSelectedStateStore={addressesSelectedStateStore}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Éléments par page :"
      />
    </>
  )
}

function TableHeader(props: PropsWithChildren<{}>) {
  return (
    <TableCell>
      <Typography variant="h6">{props.children}</Typography>
    </TableCell>
  )
}

const useRowStyles = makeStyles(function (theme) {
  const borderRadius = 4
  return {
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    historyContainer: {
      padding: theme.spacing(2),
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: '1200px',
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    '-expanded': {
      backgroundColor: theme.palette.grey[100],
    },
    selectedTab: (props: { expanded: boolean }) => {
      return {
        backgroundColor: props.expanded
          ? theme.palette.grey[300]
          : theme.palette.grey[100],
      }
    },
    tabList: {
      border: 'none',
      margin: 0,
      padding: 0,
    },
    tab: {
      padding: theme.spacing(1),
      display: 'inline-block',
      borderTopRightRadius: borderRadius,
      borderTopLeftRadius: borderRadius,
      cursor: 'pointer',
    },
    tabPanel: {
      padding: theme.spacing(2),
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
    },
    appointmentButton: {
      ...button(),
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }
})

type RafRowProps = {
  raf: ExtendedFullRaf
  addressesSelectedStateStore: ProfunctorState<AddressesSelectedState>
}

function RafRow(props: RafRowProps) {
  const { raf, addressesSelectedStateStore } = props
  const [isExpanded, setIsExpanded] = useState(false)
  const { contacts } = raf
  const { state, setState } = addressesSelectedStateStore.promap<
    AddressSelectedState | undefined
  >({
    get: function get(stateValue) {
      return stateValue[raf.id]
    },
    set: function set(newAddressState, prevState) {
      return {
        ...prevState,
        [raf.id]: newAddressState,
      }
    },
  })

  function onChange() {
    setState((prevState) => {
      if (prevState?.contact) {
        return {
          ...prevState,
          selected: !prevState?.selected,
        }
      }
      return {
        selected: true,
        contact: contacts[0],
      }
    })
  }

  function onSelect(contact: ExtendedContact) {
    setState((prevState) => {
      return {
        selected: prevState?.selected ?? false,
        contact: contact,
      }
    })
  }

  const styles = useRowStyles({ expanded: isExpanded })

  const selectedContact = state?.contact ?? contacts[0]

  return (
    <>
      <TableRow
        className={classNames(styles.root, isExpanded && styles['-expanded'])}
      >
        <TableCell>
          <Checkbox checked={state?.selected ?? false} onChange={onChange} />
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="column">
            <div className={styles.tabList}>
              {contacts.map((contact) => {
                const isSelected = selectedContact.id === contact.id
                return (
                  <div
                    className={classNames(
                      styles.tab,
                      isSelected && styles.selectedTab,
                    )}
                    key={contact.id}
                    onClick={() => onSelect(contact)}
                  >
                    <Typography color={isSelected ? 'primary' : 'inherit'}>
                      {capitalize(contact.type)}
                    </Typography>
                  </div>
                )
              })}
            </div>
            {selectedContact && (
              <div
                className={classNames(styles.selectedTab, styles.tabPanel)}
                key={selectedContact.id}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Link target="_blank" to={`/Contact/${selectedContact.id}`}>
                      {concatNames({
                        nom: selectedContact.nom,
                        prenom: selectedContact.prenom,
                        raison_sociale: selectedContact.raison_sociale,
                      } as Contact)}
                    </Link>
                  </Grid>
                  <Grid item xs={4}>
                    {selectedContact.fixe && (
                      <div>{`${selectedContact.fixe} (fixe)`}</div>
                    )}
                    {selectedContact.mobile && (
                      <div>{`${selectedContact.mobile} (mobile)`}</div>
                    )}
                  </Grid>
                  <Grid item xs={5} style={{ overflow: 'auto' }}>
                    {selectedContact.email}
                  </Grid>
                </Grid>
              </div>
            )}
          </Box>
        </TableCell>
        <TableCell>
          {
            <Link to={`/Adress/${raf.id}`} target="_blank">
              {concatAddress({
                rue: raf.adresse_rue,
                code_postal: raf.adresse_code_postal,
                ville: raf.adresse_ville,
                cote: raf.adresse_cote,
              } as Adress)}
            </Link>
          }
        </TableCell>
        <TableCell>
          {raf.next_intervention_date &&
            format(new Date(raf.next_intervention_date), 'd MMMM yyyy', {
              locale: fr,
            })}
        </TableCell>
        <TableCell>{`${raf?.prix} € `}</TableCell>
        <TableCell>
          {raf.last_intervention_date &&
            format(new Date(raf.last_intervention_date), 'd MMMM yyyy', {
              locale: fr,
            })}
        </TableCell>
        <TableCell align="center">
          {raf.hasintervention === '1' ? (
            <Chip color="secondary" label="Rdv pris" />
          ) : (
            <Link
              target="_blank"
              to={`/Intervention/create?adress_id=${raf.id}&contact_id=${selectedContact.id}`}
            >
              <Button className={styles.appointmentButton}>prendre rdv</Button>
            </Link>
          )}
        </TableCell>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classNames(isExpanded && styles['-expanded'])}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Grid className={styles.historyContainer} container spacing={4}>
              <Grid item xs={6}>
                <InterventionList adresse_id={raf.id} />
              </Grid>
              <Grid item xs={6}>
                <MessagesHistory contactId={selectedContact.id} />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
