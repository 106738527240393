import type { Theme } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { Description as DescriptionIcon } from '@material-ui/icons'
import { EnumProType } from '@willig/types/api'
import type { AxiosResponse } from 'axios'
import type { FormApi } from 'final-form'
import { isEmpty } from 'rambda'
import { useState } from 'react'
import type { EditProps } from 'react-admin'
import {
  SelectInput,
  DateInput,
  Edit,
  FormWithRedirect,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
  ReferenceInput,
} from 'react-admin'

import { useFormState } from 'react-final-form'
import { CustomNotification } from 'src/components/CustomNotification'

import { Dropzone } from 'src/components/Dropzone'
import { TypesenseWrapper } from 'src/components/Typesense/TypesenseWrapper'
import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'

import { ExportData } from './ExportData'

const useStyles = makeStyles((theme: Theme) => {
  return {
    dropzone: {
      backgroundColor: theme.palette.grey['300'],
    },
    list: { display: 'flex', flexDirection: 'column', gap: theme.spacing(1) },
    listItem: { backgroundColor: theme.palette.grey['300'] },
  }
})
export const EditView = (props: EditProps) => {
  const [createProsFile, { loading: loadingCreateProsFile }] =
    useCreate('ProsFile')
  const [updatePro, { loading: loadingUpdatePro }] = useUpdate('Pro')

  const [errorMessage, setErrorMessage] = useState('erreur')
  const notify = useNotify()
  const [open, setOpen] = useState(false)

  const saveContract = (values: any) => {
    updatePro(
      {
        payload: {
          id: values.id,
          data: values,
        },
      },
      {
        onSuccess: () => {
          if (values.files !== undefined) {
            values.files.forEach((file: string) =>
              createProsFile(
                {
                  payload: {
                    data: {
                      pro_id: values.id,
                      file_id: file,
                    },
                  },
                },
                {
                  onSuccess: () => {
                    notify('Les contrats ont bien été créés', 'success')
                  },
                  onFailure: (error: any) => {
                    setErrorMessage(
                      `Erreur: ${
                        error?.response?.headers?.['nx-error'] || error.message
                      }`,
                    )
                    setOpen(true)
                  },
                },
              ),
            )
          } else {
            notify('Contact pro mis à jour', 'success')
          }
        },
        onFailure: (error) => {
          setErrorMessage(error.message)
          setOpen(true)
        },
      },
    )
  }

  return (
    <Edit {...props}>
      <FormWithRedirect
        save={saveContract}
        render={(renderProps) => {
          const { record, saving, form, handleSubmitWithRedirect } = renderProps
          return (
            <div>
              {open && (
                <CustomNotification
                  open={open}
                  setOpen={setOpen}
                  errorMessage={errorMessage}
                />
              )}
              <TextInput fullWidth source="name" label="Nom du pro" />
              <DateInput fullWidth source="end_date" label="Fin du contrat" />
              <SelectInput
                fullWidth
                label={'Type de pro'}
                choices={convertEnumAsOptionWithId(EnumProType)}
                source="type"
              />
              <Box
                display="flex"
                flexDirection="column"
                gridGap={24}
                width={'100%'}
                marginBottom={2}
              >
                <TypesenseWrapper
                  source="default_contact_id"
                  indexName="contacts"
                  inputLabel="Contact de facturation"
                  resource="Contact"
                  emptyInput={['default_contact_id']}
                />
                <TypesenseWrapper
                  source="default_address_id"
                  indexName="adresses"
                  inputLabel="Adresse de facturation"
                  resource="Adress"
                  emptyInput={['default_address_id']}
                />
              </Box>

              <ReferenceInput
                reference="TypeTva"
                source="tva"
                label="TVA"
                fullWidth
              >
                <SelectInput optionText="code" />
              </ReferenceInput>

              <DropzoneWrapper form={form} />
              <FileList />
              <Box
                display="flex"
                flexDirection="row"
                gridGap={48}
                justifyContent="center"
              >
                <SaveButton
                  saving={saving || loadingUpdatePro || loadingCreateProsFile}
                  label={'Enregistrer le contrat'}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                />
                <ExportData id={record?.id} target="pro" />
              </Box>
            </div>
          )
        }}
      />
    </Edit>
  )
}

const FileList = () => {
  const classes = useStyles()
  const { values } = useFormState({ subscription: { values: true } }) // current values of every field in the form
  return (
    <Box mb={2}>
      <List className={classes.list}>
        {values?.files?.map((file: string) => {
          return (
            <ListItem key={file} className={classes.listItem}>
              <ListItemAvatar>
                <Avatar>
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`fichier : ${file}`} />
            </ListItem>
          )
        }) || (
          <Box pl={2} display="flex" justifyContent="center">
            <p>Aucun fichier uploadé</p>
          </Box>
        )}
      </List>
    </Box>
  )
}
type DropzoneWrapperProps = {
  form: FormApi
}

const DropzoneWrapper = (props: DropzoneWrapperProps) => {
  const { form } = props
  const classes = useStyles()
  const notify = useNotify()
  const { values } = useFormState({ subscription: { values: true } }) // current values of every field in the form
  const dropzoneOnSettled = (
    data: PromiseSettledResult<AxiosResponse | void>[],
    formValue: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  ) => {
    const files = data.map((file: any) => file.value.data.value)
    const previousFiles = values?.files || []
    formValue.change('files', [...previousFiles, ...files])
    if (!isEmpty(data)) {
      notify('Les fichiers ont été envoyé sur le serveur')
    }
  }
  const handleError = (error: string) => {
    notify(error, 'error')
  }
  return (
    <Box py={2}>
      <Dropzone
        className={classes.dropzone}
        endpoint="File"
        onSettled={(data: PromiseSettledResult<AxiosResponse | void>[]) =>
          dropzoneOnSettled(data, form)
        }
        onError={handleError}
      />
    </Box>
  )
}
