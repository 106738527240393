import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { TextInput } from 'react-admin'

export const CreateEditInputs = () => {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="code" />
            <TextInput fullWidth source="label" />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
