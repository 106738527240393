import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { createContext, useContext } from 'react'

import { config } from '../config'

import type { IEnv } from './env'

type Params = {
  env: IEnv
}
export function NxHttpClient(props: Params) {
  const { env } = props
  const httpClient = axios.create({
    baseURL: env.API_URL,
    headers: {
      'nx-app-identifier': config.APP_NAME,
      'nx-app-version': config.APP_VERSION,
      'nx-app-install': navigator?.userAgent || 'unknown',
    },
  })

  httpClient.interceptors.request.use((requestConfig) => {
    const jwt = localStorage.getItem('jwt')
    if (!jwt) {
      return requestConfig
    }

    return {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        jwt,
      },
    }
  })

  return httpClient
}
export const NxHttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useNxHttpClient(): AxiosInstance {
  const nxHttpClient = useContext(NxHttpClientContext)

  if (!nxHttpClient) {
    throw new Error('Please provide an nxHttpClient object')
  }

  return nxHttpClient
}
