import Box from '@material-ui/core/Box'
import type { Price } from '@willig/types/api'
import type { FC } from 'react'
import React from 'react'
import {
  FunctionField,
  BooleanField,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  ExportButton,
  TopToolbar,
  BulkExportButton,
} from 'react-admin'
import type { ListProps, FilterProps } from 'react-admin'
import { money } from 'src/libs/money'

import { DefaultPagination } from '../../components/defaultPagination'

function ListActions() {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  )
}

export const ListView = (props: ListProps) => {
  return (
    <List
      {...props}
      filters={<CatalogFilters />}
      bulkActionButtons={<BulkExportButton />}
      actions={<ListActions />}
      pagination={<DefaultPagination />}
      perPage={50}
    >
      <Datagrid rowClick="edit">
        <TextField source="type" />
        <TextField source="mode" />
        <TextField source="name" />
        <ReferenceField
          label="Appareil"
          source="appareil_id"
          reference="Appareil"
        >
          <TextField source="label" />
        </ReferenceField>
        <TextField source="gas" label="Famille du combustible" />
        <ReferenceField
          label="Prix (simple, medium, hard)"
          source="price"
          reference="Price"
        >
          <FunctionField
            render={(record?: unknown) => {
              const price = record as Price | undefined
              if (!price) {
                return ''
              }

              return (
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr 1fr"
                  textAlign="right"
                >
                  <span>
                    {price.price_simple ? money(price?.price_simple) : '-'}
                  </span>
                  <span>
                    {price.price_medium ? money(price?.price_medium) : '-'}
                  </span>
                  <span>
                    {price.price_hard ? money(price?.price_hard) : '-'}
                  </span>
                </Box>
              )
            }}
          />
        </ReferenceField>
        <BooleanField source="collective" />
        <TextField source="extra" />
      </Datagrid>
    </List>
  )
}
const CatalogFilters: FC<Partial<FilterProps>> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Nom" source="name" alwaysOn />
      <TextInput label="Type" source="type" alwaysOn />
      <TextInput label="Mode" source="mode" alwaysOn />
      <TextInput label="Combustible" source="gas" alwaysOn />
    </Filter>
  )
}
