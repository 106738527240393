import { makeStyles } from '@material-ui/core/styles'

import { ProContainer } from './ProContainer'
import { SpecialEventTypeContainer } from './SpecialEventTypeContainer'
import { SweepCalendar } from './SweepCalendar'

const useStyles = makeStyles((theme) => {
  return {
    calendarContainer: {
      display: 'flex',
      width: '100%',
      '& > *': { flex: 1 },
    },
    calendarWrapper: {
      border: '1px solid black',
      borderRadius: '4px',
    },
    agendaBody: {
      display: 'flex',

      gap: `${theme.spacing(1)}px`,
    },
  }
})

type sweeps = { sweep_id: string; name: string }

type AgendaBodyProps = {
  contractListState: boolean
  specialEventTypesListState: boolean
  selectedSweeps: sweeps[]
  initialDate: string
  setInitialDate: React.Dispatch<React.SetStateAction<string>>
}

export type SelectedEvent = {
  id: string | undefined
  type: 'Intervention' | 'SpecialEvent'
}

export const AgendaBody = (props: AgendaBodyProps) => {
  const { calendarWrapper, calendarContainer, agendaBody } = useStyles()
  const {
    contractListState,
    specialEventTypesListState,
    selectedSweeps,
    initialDate,
  } = props

  return (
    <div className={agendaBody}>
      {contractListState && <ProContainer />}
      {specialEventTypesListState && <SpecialEventTypeContainer />}
      <div className={calendarContainer}>
        {selectedSweeps
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((selectedSweep: { sweep_id: string; name: string }) => (
            <div className={calendarWrapper} key={selectedSweep.sweep_id}>
              <SweepCalendar
                sweep_id={selectedSweep.sweep_id}
                name={selectedSweep.name}
                initialDate={initialDate}
              />
            </div>
          ))}
      </div>
    </div>
  )
}
