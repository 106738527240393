import { Chip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import type { Record } from 'ra-core'
import React from 'react'
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  TextInput,
} from 'react-admin'
import GeoApiGouv from 'src/components/GeoApiGouv'

type CreateEditInputsProps = {
  record?: Record
}

export const CreateEditInputs = (props: CreateEditInputsProps) => {
  const { record } = props
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box>{record?.pro_id && <Chip color="secondary" label="Pro" />}</Box>
          <Box>
            <GeoApiGouv source="rue" label="Rue" />
          </Box>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="code_postal" />
            <TextInput fullWidth source="ville" />
          </Box>
          <Box display="flex" gridGap={16}>
            <ReferenceInput
              perPage={0}
              fullWidth
              label="Zone"
              source="zone"
              reference="Zone"
              filterToQuery={(searchText) => {
                return { label: searchText }
              }}
              validate={[required()]}
            >
              <AutocompleteInput optionText="label" />
            </ReferenceInput>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box>
            <TextInput fullWidth source="etage" />
            <TextInput fullWidth source="cote" label="Intitulé d'adresse" />
            <TextInput fullWidth source="ancienne_ville" />
            <TextInput multiline fullWidth source="commentaire" />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
