function joinWords(words: string[], separator: string) {
  return words.filter(Boolean).join(separator)
}

export const money = (amount: number | string, suffix: string = '') => {
  if (Number.isNaN(amount)) return amount

  const formatted = amount.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  })

  if (formatted === 'pas de facture') {
    return formatted
  }

  return joinWords([formatted, suffix], ' ').replace('TTC TTC', 'TTC')
}
