import React from 'react'
import type { EditProps } from 'react-admin'

import { SimpleForm, Edit } from 'react-admin'

import { CreateEditInputs } from './CreateEditInputs'

export const EditView = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm>
      <CreateEditInputs />
    </SimpleForm>
  </Edit>
)
