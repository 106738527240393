export function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ'
}
export function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

export function formatUrl(url: string) {
  return url.replace(/(\/)+$/, '') // remove trailing slash
}
