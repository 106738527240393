import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'

import InputLabel from '@material-ui/core/InputLabel'

import Select from '@material-ui/core/Select'
import React from 'react'

type option<T> = {
  value: T
  name?: string
}

type SelectInputProps<T> = {
  choices: option<T>[]
  initialState?: T
  label: string
  onChange: (value: T) => void
}

export function SelectInput<T>(props: SelectInputProps<T>) {
  const { choices, initialState, label, onChange } = props

  const [state, setState] = React.useState<T | undefined>(initialState)
  const handleChange = (event: React.ChangeEvent<option<T>>) => {
    const { value } = event.target
    setState(value)
    onChange(value)
  }

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state}
          label="Age"
          onChange={handleChange as any}
        >
          {choices.map((option, index) => (
            <option
              key={index}
              selected={option.value === state}
              value={option.value as any}
            >
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
