import React from 'react'
import type { ListProps } from 'react-admin'
import { Datagrid, List, TextField, BulkExportButton } from 'react-admin'

export const ListView = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={<BulkExportButton />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="label" />
      </Datagrid>
    </List>
  )
}
