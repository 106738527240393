export function convertEnumAsOptionWithId(e: any) {
  return Object.entries(e).map(([name, value]) => {
    return { name: name, id: value }
  })
}

export function convertEnumAsOptionWithValue<T>(e: T) {
  return Object.entries(e).map(([name, value]) => {
    return { name: name, value: value }
  })
}
