import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { useCallback, useMemo } from 'react'
import { required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import type { InterventionCreate } from 'src/types/api/extendedTypes'

import type { isProState } from '../interventionForm'

import ContactReferenceInput from './ContactReferenceInput'
import { SuggestedResident } from './SuggestedResident'

type ResidentContactProps = {
  search: any
  setSearch: any
  stateProf?: ProfunctorState<isProState>
}
export const ResidentContact = (props: ResidentContactProps) => {
  const { search, setSearch, stateProf } = props
  const form = useForm()
  const { values } = useFormState<Partial<InterventionCreate>>({
    subscription: { values: true },
  })

  const callback = useCallback(
    (choice: string | null) => {
      if (choice) {
        form.change('contact_id', choice)
      }
      setSearch(true)
    },
    [form, setSearch],
  )
  const filterToQuery = useCallback((searchText: string) => {
    return {
      nom: searchText,
    }
  }, [])
  const validate = useMemo(() => required(), [])

  if (!values.adresse_id) return null

  return (
    <div>
      <ContactReferenceInput
        inputLabel="Contact"
        target={'contact_id'}
        source="contact_id"
        reference="Contact"
        validate={validate}
        perPage={10}
        filterToQuery={filterToQuery}
        emptyContactInput={['contact_id']}
        stateProf={stateProf}
      />
      {!values.contact_id && !search && (
        <SuggestedResident
          adresse_id={values.adresse_id as string}
          onSelect={callback}
        />
      )}
    </div>
  )
}
