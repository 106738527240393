import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import type {
  FullContract,
  FullInterventionsWithoutTime,
} from '@willig/types/api'

import { format, differenceInDays } from 'date-fns'
import { fr } from 'date-fns/locale'
import lodash, { isEmpty } from 'lodash'
import { useMemo } from 'react'

import { useDataProvider, useGetOne } from 'react-admin'
import { useQuery } from 'react-query'

import { useList } from 'src/libs/useGetList'

import type { EventPayload } from './SweepCalendar'

const useStylesContract = makeStyles((theme) => {
  return {
    draggable: {
      borderRadius: '4px',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(0.5)}px`,
    },
    greenContract: {
      backgroundColor: theme.palette.primary.light,
    },
    yellowContract: {
      backgroundColor: theme.palette.warning.light,
    },
    redContract: {
      backgroundColor: theme.palette.error.light,
    },
    undefinedEcheanceContract: {
      backgroundColor: theme.palette.secondary.light,
    },
    contractContainer: {
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
    },
  }
})
type DraggableContainerProps = { pro_id: string }
export const DraggableContainer = (props: DraggableContainerProps) => {
  const { pro_id } = props
  const classes = useStylesContract()

  const { data: allContracts, isLoading: loading } = useList<FullContract>(
    'FullContract',
    {
      pagination: { page: 0, perPage: 0 },
      filter: { pro_id: [pro_id] },
      sort: { order: 'ASC', field: 'adresse_ville' },
    },
    { enabled: Boolean(pro_id) },
  )
  const dataProvider = useDataProvider()
  const { data: interventions } = useQuery(
    ['getInterventionsCalendarList', {}],

    () =>
      dataProvider.getList<FullInterventionsWithoutTime>(
        'AllEventsForCalendar',
        {
          pagination: { page: 0, perPage: 0 },
          sort: { field: 'rdv_date', order: 'asc' },
          filter: { pro_id },
        },
      ),
  )
  console.log('allIntervention', interventions)
  console.log('allContract', allContracts)

  const filteredcontracts = useMemo(() => {
    if (interventions !== undefined && interventions?.data?.length) {
      return lodash
        .differenceWith(
          allContracts?.data,
          interventions.data,
          (contract, intervention) => {
            return (
              contract.id === intervention.contract_id &&
              intervention.status === 1
            )
          },
        )
        .sort(streetSort)
    }
  }, [allContracts, interventions])

  console.log('filteredContracts', filteredcontracts)

  return (
    <AccordionDetails className={classes.contractContainer}>
      <Box margin="auto">
        {loading && <CircularProgress color="secondary" />}
      </Box>

      {!isEmpty(filteredcontracts) ? (
        filteredcontracts?.map((contract) => (
          <DraggableItem key={contract.id} contract={contract} />
        ))
      ) : (
        <div style={{ textAlign: 'center' }}>Aucun Contrat</div>
      )}
    </AccordionDetails>
  )
}
type DraggableItemProps = {
  contract: FullContract
}

const DraggableItem = (props: DraggableItemProps) => {
  const { contract } = props
  const classes = useStylesContract()
  const {
    adresse_rue,
    adresse_id,
    adresse_code_postal,
    adresse_ville,
    total_installations,
    installations_todo,
    total_price,
    next_intervention_date,
  } = contract

  const conditionalStyle = useMemo(() => {
    if (next_intervention_date) {
      const dayDiff = differenceInDays(
        new Date(next_intervention_date).getTime(),
        new Date().getTime(),
      )
      if (dayDiff < 0) return classes.redContract
      if (dayDiff >= 0 && dayDiff < 30) return classes.yellowContract
      return classes.greenContract
    }
    return classes.undefinedEcheanceContract
  }, [
    next_intervention_date,
    classes.greenContract,
    classes.redContract,
    classes.undefinedEcheanceContract,
    classes.yellowContract,
  ])

  const { data: address, loading } = useGetOne('Adress', adresse_id)

  return (
    <div
      data-title={`${adresse_rue} ${adresse_code_postal} ${adresse_ville}`}
      data-payload={JSON.stringify({ contract } as EventPayload)}
      data-event-type={'intervention'}
      className={`item-class ${classes.draggable} ${conditionalStyle}`}
    >
      <small>
        {installations_todo} installations à faire (sur {total_installations})
      </small>
      <small>
        {next_intervention_date !== undefined &&
          next_intervention_date !== null &&
          `Echeance:
          ${format(new Date(next_intervention_date), 'd MMMM yyyy', {
            locale: fr,
          })}`}
      </small>

      {total_price && <span>{total_price} euros (devis)</span>}
      <span>
        {adresse_rue}, {adresse_code_postal} {adresse_ville}
      </span>

      {!loading && address && <span>{address?.cote ?? ''}</span>}
    </div>
  )
}

function streetSort(a: FullContract, b: FullContract) {
  const first = a.adresse_rue.split(' ').pop() ?? ''
  const second = b.adresse_rue.split(' ').pop() ?? ''
  return first.localeCompare(second)
}
