import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { isEmpty } from 'lodash'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useCallback, useMemo } from 'react'
import { required } from 'react-admin'

import { useForm, useFormState } from 'react-final-form'

import type { isProState } from '../interventionForm'

import AdresseRefrenceInput from './AdresseReferenceInput'
import { ResidentContact } from './ResidentContact'

type ResidentInformationProps = {
  setSearch: Dispatch<SetStateAction<boolean>>
  search: boolean
  stateProf?: ProfunctorState<isProState>
  searchQuery?: string
}
export const ResidentInformation = (props: ResidentInformationProps) => {
  const { setSearch, search, stateProf, searchQuery } = props
  const { values } = useFormState({ subscription: { values: true } })
  const form = useForm()
  useEffect(() => {
    if (isEmpty(values.adresse_id)) {
      form.change('contact_id', null)
      setSearch(false)
    }
  }, [values.adresse_id, setSearch, form])
  const filterToQuery = useCallback((searchText: string) => {
    return {
      rue: searchText,
    }
  }, [])
  const RequiredUseMemo = useMemo(() => required(), [])
  return (
    <>
      <AdresseRefrenceInput
        searchQuery={searchQuery}
        stateProf={stateProf}
        filterToQuery={filterToQuery}
        inputLabel="Adresse"
        source="adresse_id"
        reference="Adress"
        validate={RequiredUseMemo}
        perPage={10000}
        target={'adresse_id'}
        emptyAdresseInput={['adresse_id']}
      />
      <ResidentContact
        search={search}
        setSearch={setSearch}
        stateProf={stateProf}
      />
    </>
  )
}
