const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then((response) => {
      response.getCLS(onPerfEntry)
      response.getFID(onPerfEntry)
      response.getFCP(onPerfEntry)
      response.getLCP(onPerfEntry)
      response.getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
