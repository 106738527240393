import { Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import type { ListProps } from 'react-admin'
import {
  EditButton,
  Button,
  FunctionField,
  Datagrid,
  List,
  TextField,
  useDataProvider,
} from 'react-admin'

import { DefaultPagination } from '../../components/defaultPagination'

const useStyles = makeStyles((theme) => {
  return {
    popOver: {
      padding: theme.spacing(2),
      display: 'flex',
      gap: theme.spacing(2),
    },
  }
})

export const ListView = (props: ListProps) => {
  return (
    <List {...props} pagination={<DefaultPagination />} perPage={50}>
      <Datagrid>
        <TextField source="firstname" label="Nom" />
        <TextField source="lastname" label="Prénom" />
        <TextField source="email" label="Email" />
        <FunctionField
          source="is_pro"
          label="Professionnel / Particulier"
          render={(value: any) => {
            const isPro = value ? 'professionnel' : 'particulier'
            return isPro
          }}
        />
        <ActionsCol />
      </Datagrid>
    </List>
  )
}

function ActionsCol(props: any) {
  const dataProvider = useDataProvider()

  async function recoverpassword(id: string, create: boolean, email: string) {
    const data = (await dataProvider.recoverPassword(id, create)) as any
    const title = create ? 'Créer' : 'Renouveller'
    const formattedBody = `Bonjour,%0D%0Avous trouverez ci dessous le lien pour ${title.toLowerCase()} votre mot de passe: %0D%0A ${
      data.data.value
    } %0D%0A %0D%0ACordialement,%0D%0AL'équipe willig`

    window.location.href = `mailto:${email}?subject=${title} le mot de passe&body=${formattedBody}`
  }

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div style={{ textAlign: 'right' }}>
      <EditButton
        label="Editer"
        basePath={props.basePath}
        record={props.record}
      />
      <>
        <Button
          color="primary"
          onClick={handleClick}
          label="Gestion des mots de passe"
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.popOver}>
            <Button
              label="Creation"
              variant="contained"
              onClick={() =>
                recoverpassword(props.record.id, true, props.record.email)
              }
            />
            <Button
              label="Edition"
              variant="contained"
              onClick={() =>
                recoverpassword(props.record.id, false, props.record.email)
              }
            />
          </div>
        </Popover>
      </>
    </div>
  )
}
