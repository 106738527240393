import { Build as BuildIcon } from '@material-ui/icons'

import { RessourceCreate } from './Create'
import { RessourceEdit } from './Edit'
import { RessourceList } from './List'
import { RessourceShow } from './Show'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: RessourceCreate,
  edit: RessourceEdit,
  list: RessourceList,
  show: RessourceShow,
  icon: BuildIcon,
}
