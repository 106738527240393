import type { Contact } from '@willig/types/api'
import { EnumContactType } from '@willig/types/api'
import React from 'react'
import type { CreateProps } from 'react-admin'
import { SimpleForm, Create } from 'react-admin'

import { CreateEditInputs } from './CreateEditInputs'

export const CreateView = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm validate={validateForm}>
      <CreateEditInputs />
    </SimpleForm>
  </Create>
)

export function validateForm(values: Contact) {
  const { type } = values
  let errors: Partial<Record<keyof Contact, string | undefined>> = {}
  if (!values.type) {
    errors.type = 'ra.validation.required'
  }

  if (
    type === EnumContactType.particulier ||
    type === EnumContactType.contact
  ) {
    errors = { ...errors, ...validatePhysical(values) }
  }
  if (
    type === EnumContactType.professionnel ||
    type === EnumContactType.collectivité ||
    type === EnumContactType.syndic ||
    type === EnumContactType.gestionnaire
  ) {
    errors = { ...errors, ...validateJuridical(values) }
  }

  return errors
}

export function validatePhysical(
  values: Contact,
): Partial<Record<keyof Contact, string | undefined>> {
  const { nom, prenom, accept_sms, accept_email } = values
  return {
    nom: nom ? undefined : 'ra.validation.required',
    prenom: prenom ? undefined : 'ra.validation.required',
    accept_sms: accept_sms !== undefined ? undefined : 'ra.validation.required',
    accept_email:
      accept_email !== undefined ? undefined : 'ra.validation.required',
  }
}

export function validateJuridical(
  values: Contact,
): Partial<Record<keyof Contact, string | undefined>> {
  const { raison_sociale } = values
  return {
    raison_sociale: raison_sociale ? undefined : 'ra.validation.required',
  }
}
