import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import {
  RadioButtonGroupInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  FunctionField,
} from 'react-admin'

export function CreateEditInputs() {
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="firstname" label="Nom" required />
            <TextInput fullWidth source="lastname" label="Prenom" required />
          </Box>

          <TextInput fullWidth source="email" label="Email" required />
          <RadioButtonGroupInput
            source="is_pro"
            label="Utilisateur pro"
            required
            choices={[
              { id: true, name: 'Oui' },
              { id: false, name: 'Non' },
            ]}
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <FunctionField
            label="Name"
            render={(record: any) => console.log(record)}
          />
          <ReferenceArrayInput
            required
            source="pro_ids"
            reference="Pro"
            label="Liste des pros"
            style={{ width: '300px' }}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </CardContent>
      </Card>
    </Box>
  )
}
