import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import type { LayoutProps } from 'react-admin'
import { Layout as RaLayout } from 'react-admin'
import { config } from 'src/config'

import { AppBar } from './AppBar'

import { BreadCrumbs } from './BreadCrumbs'

import { Menu } from './Menu'
import { border } from './theme'

export { theme } from './theme'

const useStyles = makeStyles((theme) => {
  return {
    innerHeader: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: border,
      paddingLeft: `${theme.spacing(4)}px`,
      paddingRight: `${theme.spacing(4)}px`,
    },

    roleSwitcher: {
      marginLeft: 'auto',
    },
  }
})

export function Layout(props: LayoutProps) {
  const { children } = props
  const styles = useStyles()

  return (
    <RaLayout {...props} menu={Menu} appBar={AppBar as any}>
      <Box className={styles.innerHeader}>
        <BreadCrumbs />
      </Box>
      <Box padding={4}>{children}</Box>
      <Box paddingY={1} paddingX={4}>
        <Typography variant="body2" align="center">
          {config.APP_RELEASE}
        </Typography>
      </Box>
    </RaLayout>
  )
}
