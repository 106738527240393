export const borderWidths = {
  "chunky": "8px",
  "fat": "4px",
  "regular": "2px",
  "hairline": "1px"
} as const

export const colors = {
  "onError": "rgba(255, 255, 255, 1)",
  "onAccent": "rgba(255, 255, 255, 1)",
  "onAmbient": "rgba(255, 255, 255, 1)",
  "onSurface": "rgba(0, 0, 0, 1)",
  "onBackground": "rgba(47, 46, 46, 1)",
  "background": "rgba(242, 242, 242, 1)",
  "surface": "rgba(255, 255, 255, 1)",
  "gray": "rgba(153, 153, 151, 1)",
  "grayLight": "rgba(222, 225, 227, 1)",
  "ambient": "rgba(255, 171, 14, 1)",
  "ambientVariant": "rgba(207, 138, 6, 1)",
  "accent": "rgba(145, 201, 100, 1)",
  "accentVariant": "rgba(93, 150, 90, 1)",
  "error": "rgba(176, 0, 74, 1)"
} as const

export const fontFamilies = {
  "button": "Montserrat",
  "microbutton": "Montserrat",
  "microcopyBold": "Open Sans",
  "microcopy": "Open Sans",
  "bodySBold": "Open Sans",
  "bodyS": "Open Sans",
  "bodyM": "Open Sans",
  "bodyL": "Open Sans",
  "headingXs": "Montserrat",
  "headingS": "Montserrat",
  "headingM": "Montserrat",
  "headingL": "Montserrat"
} as const

export const fontSizes = {
  "button": "0.8125rem",
  "microbutton": "0.625rem",
  "microcopyBold": "0.625rem",
  "microcopy": "0.625rem",
  "bodySBold": "0.75rem",
  "bodyS": "0.75rem",
  "bodyM": "1rem",
  "bodyL": "1.25rem",
  "headingXs": "0.9375rem",
  "headingS": "1.25rem",
  "headingM": "1.3125rem",
  "headingL": "2.5rem"
} as const

export const fontWeights = {
  "button": 700,
  "microbutton": 700,
  "microcopyBold": 700,
  "microcopy": 300,
  "bodySBold": 700,
  "bodyS": 400,
  "bodyM": 400,
  "bodyL": 400,
  "headingXs": 700,
  "headingS": 700,
  "headingM": 700,
  "headingL": 700
} as const

export const letterSpacings = {
  "button": "0em",
  "microbutton": "0em",
  "microcopyBold": "0.03em",
  "microcopy": "0em",
  "bodySBold": "0em",
  "bodyS": "0em",
  "bodyM": "0em",
  "bodyL": "0em",
  "headingXs": "0em",
  "headingS": "0em",
  "headingM": "0em",
  "headingL": "0em"
} as const

export const lineHeights = {
  "button": "1.23",
  "microbutton": "1.20",
  "microcopyBold": "1.60",
  "microcopy": "1.20",
  "bodySBold": "1.33",
  "bodyS": "1.33",
  "bodyM": "1.50",
  "bodyL": "1.60",
  "headingXs": "1.07",
  "headingS": "1.20",
  "headingM": "1.14",
  "headingL": "1.00"
} as const

export const radii = {
  "rounded": "8px",
  "subtle": "2px",
  "hard": "0px"
} as const

export const shadows = {
  "medium": "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)"
} as const

export const spacing = {
  "vr35": "6rem",
  "vr3": "4rem",
  "vr25": "3rem",
  "vr2": "2rem",
  "vr1": "0.25rem",
  "vr0": "0.5rem"
} as const