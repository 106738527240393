import type { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

export function headingL() {
  return {
    fontFamily: 'Montserrat',
    fontSize: '40px',
    fontStyle: 'normal',
    fontHeight: 700,
    lineHeight: '40px',
    letterSpacing: '0em',
    textAlign: 'left',
  }
}

export function BodyL() {
  return {
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
  }
}
export function button(): CreateCSSProperties {
  return {
    fontFamily: 'Montserrat',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0em',
    textTransform: 'uppercase',
  }
}
