import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px',
    },
  }
})

export function BreadCrumbs() {
  const styles = useStyles()
  // TODO fix breadcrumb
  return <div className={styles.root}></div>
}
