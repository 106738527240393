import React from 'react'
import type { ListProps } from 'react-admin'
import { ReferenceField, Datagrid, List, TextField } from 'react-admin'

export const ListView = (props: ListProps) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="label" />
        <ReferenceField label="Zone" source="zone_id" reference="Zone">
          <TextField source="label" />
        </ReferenceField>
      </Datagrid>
    </List>
  )
}
