import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import type {
  DatePickerView,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'
import { KeyboardDatePicker } from '@material-ui/pickers'

import type { PropsWithChildren } from 'react'
import React from 'react'

import type { UseControllerProps } from 'react-hook-form'
import { useController } from 'react-hook-form'

type PickerProps = {
  options: { label: string; value: string | number }[]
  name: string
  required?: boolean
  label: string
}

export function Picker(props: PickerProps) {
  const { options, name, required, label } = props
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules: { required } })

  const { onChange, value } = field

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    if (event.target.value === '') {
      return onChange(undefined)
    }
    onChange(event)
  }

  return (
    <FormInput required={required} label={label}>
      <Select
        {...field}
        displayEmpty
        onChange={handleChange}
        value={value ?? ''}
        error={!!error}
        variant="filled"
      >
        {!required && <MenuItem value="">Peu importe</MenuItem>}
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormInput>
  )
}

type DatePickerProps = Pick<KeyboardDatePickerProps, 'views'> & {
  name: string
  rules?: UseControllerProps['rules']
  label: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  onChange?: () => void
}

export function DatePicker(props: DatePickerProps) {
  const {
    name,
    rules,
    label,
    minDate,
    maxDate,
    disabled,
    views = ['date'],
  } = props
  const { field } = useController({ name, rules, defaultValue: undefined })
  const format = getFormat(views)

  return (
    <KeyboardDatePicker
      minDate={minDate}
      minDateMessage="La date ne peut pas etre inférieure à la date du jour"
      maxDate={maxDate}
      maxDateMessage="La date de fin ne doit pas dépasser la date de début de plus d'un an"
      disableToolbar
      variant="inline"
      inputVariant="filled"
      format={format}
      margin="normal"
      views={views}
      disabled={disabled}
      id="date-picker-inline"
      label={label}
      style={{ marginTop: '0' }}
      {...field}
    />
  )
}

const useFormInputStyle = makeStyles((theme: Theme) =>
  createStyles({
    inputLabelRoot: {
      transform: `translate(${theme.spacing(1)}px, 24px) scale(1)`,
    },
    inputLabelShrink: {
      transform: `translate(${theme.spacing(1.5)}px, ${theme.spacing(
        1,
      )}px) scale(0.75)`,
    },
  }),
)
type FormInputProps = {
  required?: boolean
  label: string
}

export function FormInput(props: PropsWithChildren<FormInputProps>) {
  const { required, label, children } = props
  const styles = useFormInputStyle()

  return (
    <FormControl required={required}>
      <InputLabel
        classes={{
          root: styles.inputLabelRoot,
          shrink: styles.inputLabelShrink,
        }}
        shrink
      >
        {label}
      </InputLabel>
      {children}
    </FormControl>
  )
}

function getFormat(view: DatePickerView[]) {
  if (view.includes('date')) {
    return 'dd/MM/yyyy'
  }
  if (view.includes('year') && view.includes('month')) {
    return 'MMMM yyyy'
  }
  if (view.includes('year')) {
    return 'yyyy'
  }
  if (view.includes('month')) {
    return 'MMMM'
  }
}
