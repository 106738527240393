import type { Adress, Contact, Pro } from '@willig/types/api'
import React from 'react'
import type { ListProps } from 'react-admin'
import {
  DateField,
  Datagrid,
  List,
  FunctionField,
  Link,
  BulkExportButton,
} from 'react-admin'
import { concatAddress, concatNames } from 'src/libs/concatNames'
import { useOne } from 'src/libs/useOne'

import { DefaultPagination } from '../../components/defaultPagination'

export const ListView = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={<BulkExportButton />}
      pagination={<DefaultPagination />}
      perPage={50}
    >
      <Datagrid>
        <FunctionField<Pro>
          reference="id"
          source="name"
          label="Nom du pro"
          render={(record) => (
            <Link to={`/Pro/${record?.id}`} target="_blank">
              {record?.name}
            </Link>
          )}
        />
        <DateField source="end_date" label="Date de fin" />

        <FunctionField<Pro>
          reference="Contact"
          label="Contact de facturation"
          render={(record) => <ContactName pro={record} />}
        />

        <FunctionField<Pro>
          reference="Adresse"
          label="Adresse de facturation"
          render={(record) => <AdressName pro={record} />}
        />
      </Datagrid>
    </List>
  )
}

type AddressNameProps = {
  pro: Pro | undefined
}

function AdressName(props: AddressNameProps) {
  const { pro } = props
  const { data: adressName } = useOne<Adress>(
    'Adress',
    {
      id: pro?.default_address_id ?? 'null',
    },
    { enabled: Boolean(pro?.default_address_id) },
  )
  return (
    <Link to={`/adress/${adressName?.data.id}`} target="_blank">
      {concatAddress({
        rue: adressName?.data.rue,
        code_postal: adressName?.data.code_postal,
        ville: adressName?.data.ville,
        cote: adressName?.data.cote,
      } as Adress)}
    </Link>
  )
}

function ContactName(props: AddressNameProps) {
  const { pro } = props
  const { data: contact } = useOne<Contact>(
    'Contact',
    {
      id: pro?.default_contact_id ?? 'null',
    },
    { enabled: Boolean(pro?.default_contact_id) },
  )
  return (
    <Link to={`/contact/${contact?.data.id}`} target="_blank">
      {concatNames(contact?.data)}
    </Link>
  )
}
