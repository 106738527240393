import packageJson from '../../../package.json'

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}`,
}

export const features = {
  rafV2: process.env.NODE_ENV === 'development',
  disableContactType: false,
}
