import { Button, Modal, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { useNotify } from 'react-admin'
import { useEnv } from 'src/adapters/env'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'

import type { AddressesSelectedState } from '..'

import { MailForm } from './MailForm'
import { SmsForm } from './SmsForm'

type ContactModalProps = {
  contactType: 'SMS' | 'MAIL'
  count: number
  addressesSelectedState: AddressesSelectedState
}

export type FormData = {
  subject?: string
  template?: string
  freeContent?: string
  title?: string
}

export const useModalStyle = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      minWidth: theme.spacing(50),
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    rootModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rootButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.paper,
      marginLeft: theme.spacing(1),
      width: 'fit-content',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    typo: {
      color: theme.palette.grey[600],
      marginBottom: theme.spacing(1),
    },
    inputLabelRoot: {
      transform: `translate(${theme.spacing(1)}px, 24px) scale(1)`,
      color: theme.palette.grey[600],
    },
    inputLabelShrink: {
      transform: `translate(${theme.spacing(1.5)}px, ${theme.spacing(
        1,
      )}px) scale(0.75)`,
    },
  }),
)

export function ContactModal(props: ContactModalProps) {
  const { contactType, addressesSelectedState, count } = props

  const [open, setOpen] = useState(false)
  const [notSendModal, setNotSendModal] = useState(false)
  const [notContacted, setNotContacted] = useState<Array<{} | undefined>>([])
  const handleOpen = () => setOpen(true)
  const handleOpenNotSend = () => setNotSendModal(true)
  const handleClose = () => setOpen(false)
  const handleCloseNotSend = () => setNotSendModal(false)

  const notify = useNotify()
  const env = useEnv()
  const nxHttpClient = useNxHttpClient()

  function onSubmit(data: FormData) {
    const contacts = Object.values(addressesSelectedState)
      .filter((adresse) => {
        if (!adresse || !adresse.contact) return null
        return (
          adresse.selected &&
          (contactType === 'SMS'
            ? adresse.contact.accept_sms === 1 &&
              adresse.contact.mobile !== null
            : adresse.contact.email !== null &&
              (adresse.contact?.accept_email === 1 ||
                adresse.contact?.accept_email === null))
        )
      })
      .map((adresse) => {
        if (!adresse || !adresse.contact) return null
        return {
          id: adresse.contact.id,
          to: env.IS_DEV
            ? 'thomas.salvi@nartex.fr'
            : contactType === 'MAIL'
            ? adresse.contact.email
            : adresse.contact.mobile,
        }
      })

    const mailBody =
      data.template === '__blank'
        ? { template: data.template, text: data.freeContent }
        : { template: data.template }

    const content = {
      content: {
        subject: data.title,
        to: contacts,
        body: contactType === 'SMS' ? { text: data.subject } : mailBody,
      },
    }
    const notContacts = Object.values(addressesSelectedState).filter(
      (adresse) => {
        if (!adresse) return null
        return (
          adresse.contact &&
          adresse.selected === true &&
          (contactType === 'SMS'
            ? adresse.contact.accept_sms === 0 ||
              adresse.contact.mobile === null
            : adresse.contact?.accept_email === 0 ||
              adresse.contact?.email === null)
        )
      },
    )
    setNotContacted(notContacts)
    nxHttpClient.patch('/Contact/send', content).then(() => {
      contacts.length > 0 &&
        notify(
          `${contacts.length} messages envoyés`,
          'success',
          undefined,
          false,
          1500,
        )
      notContacts.length > 0 &&
        notify(
          `${notContacts.length} messages non envoyés`,
          'warning',
          undefined,
          false,
          1500,
        )
      handleClose()
      handleOpenNotSend()
    })
    handleClose()
  }

  const { modal, rootModal, rootButton, typo } = useModalStyle()

  return (
    <>
      <Button
        disabled={count === 0}
        classes={{ root: rootButton }}
        onClick={handleOpen}
      >{`envoyer un ${contactType}`}</Button>
      <Modal open={open} onClose={handleClose} className={rootModal}>
        <div className={modal}>
          <Typography variant="h4">{`Envoyer un ${contactType}`}</Typography>
          <p>{`${count} contacts sélectionnés`}</p>
          <Typography variant="subtitle2" className={typo}>
            {contactType === 'MAIL'
              ? "Quel modèle voulez-vous utiliser pour l'envoi ?"
              : 'Rédigez votre message '}
          </Typography>
          {contactType === 'SMS' ? (
            <SmsForm onSubmit={onSubmit} count={count} />
          ) : (
            <MailForm onSubmit={onSubmit} count={count} />
          )}
        </div>
      </Modal>
      {notContacted.length > 0 && (
        <>
          <Modal
            open={notSendModal}
            onClose={handleCloseNotSend}
            className={rootModal}
          >
            <div className={modal}>
              <Typography variant="h5">
                {formatText(notContacted, contactType)}
              </Typography>
              {notContacted.map((contact: any) => {
                return (
                  <p
                    key={contact.id}
                  >{`${contact.contact.nom} ${contact.contact.prenom}`}</p>
                )
              })}
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

function formatText(contact: any, contactType: any) {
  return contact.length > 1
    ? `${contact.length} contacts n'acceptent pas les ${contactType} et/ou n'ont pas renseignés de moyens de contact`
    : `${contact.length} contact n'accepte pas les ${contactType} et/ou n'a pas renseigné de moyens de contact`
}
