import type { SearchClientQuery } from 'typesense-instantsearch-adapter'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

import type { IEnv } from './env'

type SearchClientParams = {
  env: IEnv
}
export function SearchClient(params: SearchClientParams) {
  const { env } = params
  const { searchClient } = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: env.TYPESENSE_SEARCH_API_KEY,
      nodes: [
        {
          host: env.TYPESENSE_URL,
          protocol: env.TYPESENSE_PROTOCOL,
        },
      ],
    },

    // Search parameters that need to be *overridden* on a per-collection-basis go here:
    collectionSpecificSearchParameters: {
      contacts: { queryBy: 'prenom, nom, phones, raison_sociale' },
      adresses: { queryBy: 'rue, code_postal, ville, note, contact' },
      interventions: {
        queryBy:
          'nom, prenom, rue, code_postal, ville, nom_facturation, prenom_facturation, rdv_date',
      },
    },
  })
  return {
    ...searchClient,
    search(requests: SearchClientQuery[]) {
      return searchClient.search(requests.map(preprocessQuery))
    },
  }
}

function preprocessQuery(request: SearchClientQuery): SearchClientQuery {
  if (request.indexName === 'adresses') {
    return {
      ...request,
      params: {
        ...request.params,
        query: request.params.query
          .replace(/rue /i, ' ')
          .replace(/(\d+)([a-z]+)/i, `$1 $2`),
      },
    }
  }

  return request
}
