import type { AxiosError, AxiosInstance } from 'axios'
import type { AuthProvider as IAuthProvider } from 'react-admin'

type Params = {
  nxHttpClient: AxiosInstance
}

export function AuthProvider(params: Params): IAuthProvider {
  const { nxHttpClient } = params

  return {
    login: (props: { username: string; password: string }) => {
      const { username, password } = props
      const formData = new FormData()
      formData.append('login', username)
      formData.append('password', password)
      return nxHttpClient
        .post('/me', formData, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText)
          }
          return { jwt: response.data.value }
        })
        .then((resp) => {
          localStorage.setItem('jwt', resp.jwt)
        })
    },
    checkError: (error: AxiosError) => {
      const status = error.response?.status
      if (status === 401 || status === 403) {
        localStorage.removeItem('jwt')
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ redirectTo: '/login' })
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve()
    },
    checkAuth: () => {
      return localStorage.getItem('jwt')
        ? Promise.resolve()
        : Promise.reject(new Error('Erreur : JWT manquant'))
    },
    logout: () => {
      localStorage.removeItem('jwt')
      return Promise.resolve()
    },
    getIdentity: () => {
      return Promise.resolve({ id: 12054856454 })
    },
    getPermissions: () => {
      return Promise.resolve()
    },
  }
}
