import type { Theme } from '@material-ui/core'
import { Box, Card, makeStyles, Typography } from '@material-ui/core'
import type { FullAddress, FullAddressesAnomaly } from '@willig/types/api'
import { useGetOne } from 'react-admin'
import { useQuery } from 'react-query'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'
import { SafeHtml } from 'src/libs/SafeHtml'

type AnomaliesListProps = {
  address: FullAddress & { anomalies: string[] }
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: '#FFAB0E',
    },
    title: {
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  }
})
export function AnomaliesList(props: AnomaliesListProps) {
  const { address } = props
  const style = useStyles()
  const { id } = address

  const httpClient = useNxHttpClient()
  const { data: anomaliesList } = useQuery<FullAddressesAnomaly[]>(
    ['getList', id, 'FullAddressesAnomaly'],
    async () => {
      const { data } = await httpClient.get(
        `/FullAddressesAnomaly?filter[]=adresse_id,eq,${id}`,
      )

      return data
    },
    { enabled: Boolean(id) },
  )

  if (!anomaliesList || anomaliesList.length === 0)
    return (
      <Box mt={2} display="flex" gridGap={16} flexDirection="column">
        <Typography variant="overline">Pas d'anomalies</Typography>
      </Box>
    )

  const filterAnomalies = anomaliesList.filter((anomalie) => anomalie.anomalies)

  if (filterAnomalies.length === 0)
    return (
      <Box mt={2} display="flex" gridGap={16} flexDirection="column">
        <Typography variant="overline">Pas d'anomalies</Typography>
      </Box>
    )

  return (
    <Box mt={2} display="flex" gridGap={16} flexDirection="column">
      <Typography variant="overline">Liste des anomalies</Typography>
      {filterAnomalies.map((anomalie) => {
        const anomalies = JSON.parse(anomalie?.anomalies ?? '')
        return anomalies.map((singleAnomaly: any) => {
          const values = Object.entries(singleAnomaly)[0][1] as any

          return (
            <Card elevation={2} className={style.card}>
              <Typography variant="overline" className={style.title}>
                <SafeHtml>{values?.libelle}</SafeHtml>
              </Typography>
              <SafeHtml>{values?.libelle_anomalies}</SafeHtml>
              <SafeHtml>{values?.detail}</SafeHtml>
              <AnomalieDescription id={values.anomalies} />
            </Card>
          )
        })
      })}
    </Box>
  )
}

function AnomalieDescription(props: { id: string | number }) {
  const { id } = props
  const { data: anomalieDescription, loading } = useGetOne('Anomalie', id)
  if (loading) return <>Chargement ...</>
  return <SafeHtml>{anomalieDescription?.description}</SafeHtml>
}
