import React from 'react'
import type { EditProps } from 'react-admin'
import {
  Show,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  TabbedShowLayout,
  Tab,
} from 'react-admin'

export const RessourceShow = (props: EditProps) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Général">
          <DateField source="rdv_date" />
          <TextField source="rdv_time" />
          <ReferenceField source="adresse_id" reference="Adress">
            <FunctionField
              render={(record: any) =>
                `${record.rue} ${record.code_postal} ${record.ville}`
              }
            />
          </ReferenceField>
          <ReferenceField source="contact_id" reference="Contact">
            <FunctionField
              render={(record: any) => `${record.nom} ${record.prenom}`}
            />
          </ReferenceField>

          <ReferenceField source="status" reference="StatusIntervention">
            <TextField source="label" />
          </ReferenceField>

          <ReferenceField source="sweep_id" reference="User">
            <TextField source="login" />
          </ReferenceField>
        </Tab>
        <Tab label="Documents" path="documents">
          <p>à faire</p>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
