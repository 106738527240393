import Box from '@material-ui/core/Box'
import { EnumProType } from '@willig/types/api'
import React from 'react'
import type { CreateProps } from 'react-admin'
import {
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  SimpleForm,
  Create,
  required,
} from 'react-admin'

import { TypesenseInput } from 'src/components/Typesense/TypesenseInput'
import { convertEnumAsOptionWithId } from 'src/libs/enumAsOptions'

const validateProCreation = (values: any) => {
  const errors: any = {}
  if (!values.default_contact_id) {
    // eslint-disable-next-line functional/immutable-data
    errors.default_contact_id = 'Veuillez choisir un contact pro'
  }

  return errors
}

export const CreateView = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm validate={validateProCreation}>
        <TextInput fullWidth source="name" label="Nom du pro" />
        <DateInput
          fullWidth
          source="end_date"
          label="Fin du contrat"
          validate={required()}
        />

        <SelectInput
          fullWidth
          label={'Type de pro'}
          choices={convertEnumAsOptionWithId(EnumProType)}
          source={'type'}
        />
        <Box
          display="flex"
          flexDirection="column"
          gridGap={24}
          width={'100% !important'}
          marginBottom={2}
        >
          <TypesenseInput
            source="default_contact_id"
            indexName="contacts"
            inputLabel="Contact de facturation"
            emptyInputArray={['default_contact_id']}
          />
          <TypesenseInput
            source="default_address_id"
            indexName="adresses"
            inputLabel="Adresse de facturation"
            emptyInputArray={['default_address_id']}
          />
        </Box>

        <ReferenceInput reference="TypeTva" source="tva" label="TVA" fullWidth>
          <SelectInput optionText="code" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
