import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { Title } from 'react-admin'

import { AgendaBody } from './AgendaBody'
import { AgendaMenu } from './AgendaMenu'

const useStyles = makeStyles((theme) => {
  return {
    cardContent: {
      display: 'flex',
    },
    agendaWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
    },
  }
})

type sweeps = { sweep_id: string; name: string }
export const Agenda = () => {
  const [initialDate, setInitialDate] = useState(
    format(new Date(), 'yyyy-MM-dd'),
  )
  const { cardContent, agendaWrapper } = useStyles()
  const [contractListState, setContractListState] = useState<boolean>(false)
  const [specialEventTypesListState, setSpecialEventTypesListState] =
    useState<boolean>(false)
  const [selectedSweeps, setSelectedSweep] = useState<sweeps[]>([])

  const agendaMenuProps = {
    contractListState,
    setContractListState,
    specialEventTypesListState,
    setSpecialEventTypesListState,
    selectedSweeps,
    setSelectedSweep,
  }
  const agendaBodyProps = {
    contractListState,
    specialEventTypesListState,
    selectedSweeps,
  }
  return (
    <Card>
      <CardContent className={cardContent}>
        <Title title="Agenda" />
        <div className={agendaWrapper}>
          <AgendaMenu
            {...agendaMenuProps}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
          />
          <AgendaBody
            {...agendaBodyProps}
            initialDate={initialDate}
            setInitialDate={setInitialDate}
          />
        </div>
      </CardContent>
    </Card>
  )
}
