import React from 'react'
import type { CreateProps } from 'react-admin'
import { SimpleForm, Create } from 'react-admin'

import { CreateEditInputs } from './CreateEditInputs'

export const CreateView = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <CreateEditInputs />
    </SimpleForm>
  </Create>
)
