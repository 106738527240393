import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { PersonAdd as PersonAddIcon } from '@material-ui/icons'
import type { FullContactAdresse } from '@willig/types/api'
import { SimpleShowLayout, TextField } from 'react-admin'
import { useGetList } from 'src/libs/useGetList'

const useStyles = makeStyles({
  suggestedCard: {
    minWidth: 275,
    display: 'flex',
    alignItems: 'flex-end',
  },
  suggestedInfo: {
    flexGrow: 1,
  },
})

type sugesstedAdressesProps = {
  facture_contact_id: string
  cb: (choice: string | null) => void
  state: any
}
export const SuggestedAdresses = (props: sugesstedAdressesProps) => {
  const { facture_contact_id, state, cb } = props
  const filter = state.isPro
    ? {
        contact_id: [facture_contact_id],
        type_relation: 'contact',
        '!deleted': 1,
      }
    : { contact_id: [facture_contact_id], '!deleted': 1 }

  const { data, loading } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: filter,
    },
    { enabled: Boolean(facture_contact_id) },
  )

  if (state.isPro && data.length === 1) {
    cb(data[0].adresse_id)
  }

  const classes = useStyles()
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {loading && 'chargement'}
      {data &&
        data.map((res: any) => (
          <Card
            key={res.id}
            className={classes.suggestedCard}
            variant="outlined"
          >
            <CardContent className={classes.suggestedInfo}>
              <SimpleShowLayout record={res}>
                <TextField source="rue" />
                <TextField source="code_postal" />
                <TextField source="ville" />
                <TextField source="cote" label="Intitulé d'adresse" />
                <TextField source="etage" />
                <TextField source="commentaire" />
              </SimpleShowLayout>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={() => cb(res.adresse_id)}>
                Choisir
              </Button>
            </CardActions>
          </Card>
        ))}
      {!state.isPro && (
        <Card className={classes.suggestedCard} variant="outlined">
          <CardContent>
            <Button
              variant="contained"
              color="primary"
              onClick={() => cb(null)}
            >
              <PersonAddIcon />
              <span>Créer ou sélectionner une autre adresse</span>
            </Button>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
