import type { Contact } from '@willig/types/api'
import { EnumContactType } from '@willig/types/api'
import { useMemo } from 'react'

export function useIsPhysical(values: Contact) {
  return useMemo(() => {
    const { type } = values
    if (!type) return false
    if (
      type === EnumContactType.particulier ||
      type === EnumContactType.contact
    ) {
      return true
    }
    return false
  }, [values])
}

export function useIsJuridical(values: Contact) {
  return useMemo(() => {
    const { type } = values
    if (!type) return false
    if (
      type === EnumContactType.professionnel ||
      type === EnumContactType.collectivité ||
      type === EnumContactType.syndic ||
      type === EnumContactType.gestionnaire
    ) {
      return true
    }
    return false
  }, [values])
}
