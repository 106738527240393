import type { AxiosInstance } from 'axios'
import { omit } from 'lodash'
import type { CreateResult, DataProvider as IDataProvider } from 'react-admin'

import type { Params, PaginationLimit } from 'src/types/nxFiltertypes'

import { encodeFilterParams } from '../libs/nxSearchFiltersParams'

function encodeLimitParams(limit?: PaginationLimit) {
  return limit?.pageSize
    ? `${Math.max(0, limit.offset || 0)},${limit.pageSize}`
    : undefined
}

function encodeOrderParams<T>(order?: Params<T>['order']): string | undefined {
  const props = Object.entries(order || {}).map(([key, value]) => {
    if (!value) return null
    if (value === 'DESC') {
      return `${key} ${value}`
    }
    return key
  })

  return props.filter(Boolean).join(',') || undefined
}
type DataProviderParams = {
  nxHttpClient: AxiosInstance
}

export function DataProvider(params: DataProviderParams): IDataProvider {
  const { nxHttpClient } = params
  return {
    getList: (resource, paramsList) => {
      const { page, perPage } = paramsList.pagination
      const { field, order } = paramsList.sort
      if (resource === 'FullZoneCity') {
        return nxHttpClient.get(`/${resource}`).then((response) => {
          return {
            data: response.data,
            total: parseInt(response.headers['nx-total-rows']),
          }
        })
      }
      return nxHttpClient
        .get(`/${resource}`, {
          params: {
            limit: encodeLimitParams({
              pageSize: perPage,
              offset: (page - 1) * perPage,
            }),
            filter: encodeFilterParams(paramsList?.filter),
            // @ts-ignore
            order: encodeOrderParams({ [field]: order }),
            calc_rows: true,
          },
        })
        .then((response) => {
          return {
            data: response.data,
            total: parseInt(response.headers['nx-total-rows']),
          }
        })
    },

    getOne: (resource, paramsOne) => {
      return nxHttpClient
        .get(`${resource}/${paramsOne.id}`)
        .then((response) => {
          return {
            data: response.data,
          }
        })
    },

    getMany: (resource, paramsMany) => {
      const newParams = { ...paramsMany, id: paramsMany.ids, ids: undefined }
      return nxHttpClient
        .get(`/${resource}`, {
          params: {
            filter: encodeFilterParams(newParams),
          },
        })
        .then((response) => {
          return {
            data: response.data,
            total: response.headers['nx-total-rows'],
          }
        })
    },

    getManyReference: (resource, paramsRequest) => {
      const { page, perPage } = paramsRequest.pagination
      const { field, order } = paramsRequest.sort
      return nxHttpClient
        .get(`/${resource}`, {
          params: {
            limit: encodeLimitParams({ pageSize: perPage, offset: page }),
            filter: encodeFilterParams({
              ...paramsRequest?.filter,
              [paramsRequest.target]: paramsRequest.id,
            }),
            // @ts-ignore
            order: encodeOrderParams({ [field]: order }),
            calc_rows: true,
          },
        })
        .then((response) => {
          return {
            data: response.data,
            total: response.headers['nx-total-rows'],
          }
        })
    },

    update: (resource, paramsRequest) => {
      const { id, data, previousData } = paramsRequest
      return nxHttpClient.put(`/${resource}/${id}`, data).then(() => {
        return { data: { ...previousData, ...data, id } }
      })
    },

    updateMany: (resource, paramsRequest) => {
      const { ids, data } = paramsRequest
      return Promise.all(
        ids.map((id) => nxHttpClient.put(`/${resource}/${id}`, data)),
      ).then(() => {
        return { data: [...paramsRequest.ids] }
      })
    },

    create: (resource, paramsRequest) => {
      const { data } = paramsRequest
      return nxHttpClient
        .post(`/${resource}`, omit(data, 'create_dt', 'change_dt')) // remove omit deleted
        .then((response) => {
          return {
            data: {
              ...data,
              id: response.data.value,
            },
          } as CreateResult<any>
        })
    },

    delete: (resource, paramsRequest) => {
      const { id, previousData } = paramsRequest
      if (resource === 'Contact') {
        if (
          window.confirm(
            `Le contact ${id} est sur le point d'être supprimé, continuer ? `,
          )
        ) {
          return nxHttpClient.delete(`/${resource}/${id}`).then(() => {
            return sendAlertMail(id, true).then(() => {
              return { data: { ...previousData, deleted: true } as any }
            })
          })
        }
        return sendAlertMail(id, false).then(() => {
          return { data: previousData }
        })
      }
      return nxHttpClient.delete(`/${resource}/${id}`).then(() => {
        return { data: { ...previousData, deleted: true } as any }
      })
    },

    deleteMany: (resource, paramsRequest) => {
      const { ids } = paramsRequest
      return Promise.all(
        ids.map((id) => nxHttpClient.delete(`/${resource}/${id}`)),
      ).then(() => {
        return { data: [...paramsRequest.ids] }
      })
    },
    recoverPassword: (id: string, create: boolean) => {
      return nxHttpClient.patch('/UserExtranet/generateUrl', {
        id: id,
        create: create,
      })
    },
  }
  function sendAlertMail(id: string | number, confirm: boolean) {
    return nxHttpClient.patch('/Contact/send', {
      content: {
        subject: 'Alerte suppression',
        to: 'laurent.gwinner@nartex.fr',
        body: {
          template: '__blank',
          text: confirm
            ? `contact ${id} effacé depuis l'adresse ${document.location.href} `
            : `Tentative de suppression du contact ${id} effacé depuis l'adresse ${document.location.href} `,
        },
      },
    })
  }
}
