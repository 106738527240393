import {
  // https://stackoverflow.com/a/64135466
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core'
import {
  colors,
  fontFamilies,
  fontWeights,
  letterSpacings,
} from '@willig/design/tokens'

import merge from 'lodash/merge'
import type { RaThemeOptions } from 'react-admin'
import { defaultTheme } from 'react-admin'

const fontFamilyMap = {
  Montserrat: '"Montserrat", sans-serif',
  'Open Sans': '"Open Sans", sans-serif',
}

const baseFS = 14
const multiplier = 1.3
const spacing = 8
const rem = spacing * 2

export function typeScale(increment: number, comfy = false) {
  const fontSize = baseFS * multiplier ** increment
  const extraSpace = !comfy && increment < 0 ? spacing / 2 : spacing
  const lineHeight = fontSize - (fontSize % spacing) + extraSpace

  return {
    fontSize,
    lineHeight: `${lineHeight / rem}rem`,
  }
}

const options: RaThemeOptions = {
  // breakpoints: {},
  // direction: {},
  shape: {
    borderRadius: 4,
  },
  palette: {
    primary: {
      main: colors.accentVariant,
      light: colors.accent,
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      main: colors.ambient,
      contrastText: colors.onAmbient,
      light: 'rgba(24, 51, 89, 0.6)',
    },
    text: {
      primary: colors.onSurface,
      secondary: colors.onBackground,
    },
    warning: {
      main: colors.ambient,
      contrastText: colors.onAmbient,
      light: 'rgba(255, 171, 64, 1)',
    },
    error: {
      main: colors.error,
      contrastText: colors.onError,
      light: 'rgba(255, 105, 140, 1)',
    },
    success: {
      main: colors.accent,
      contrastText: colors.onAccent,
    },
    background: {
      default: colors.background,
      paper: colors.surface,
    },
    grey: {
      '200': colors.grayLight,
      '600': colors.gray,
      '900': colors.onBackground,
    },
  },
  props: {},
  spacing,
  transitions: {},
  typography: {
    htmlFontSize: rem,
    fontFamily: fontFamilies.bodyM,
    fontSize: baseFS,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: fontFamilyMap[fontFamilies.headingL],
      fontWeight: fontWeights.headingL,
      letterSpacing: letterSpacings.headingL,

      ...typeScale(5),
    },
    h2: {
      fontFamily: fontFamilyMap[fontFamilies.headingM],
      fontWeight: fontWeights.headingM,
      letterSpacing: letterSpacings.headingM,

      ...typeScale(4),
    },
    h3: {
      fontFamily: fontFamilyMap[fontFamilies.headingS],
      fontWeight: fontWeights.headingS,
      letterSpacing: letterSpacings.headingS,

      ...typeScale(3),
    },
    h4: {
      fontFamily: fontFamilyMap[fontFamilies.headingXs],
      fontWeight: fontWeights.headingXs,
      letterSpacing: letterSpacings.headingXs,

      ...typeScale(2),
    },
    h5: {
      fontFamily: fontFamilyMap[fontFamilies.headingXs],
      fontWeight: fontWeights.headingXs,
      letterSpacing: letterSpacings.headingXs,
      textTransform: 'uppercase',

      ...typeScale(1),
    },
    h6: {
      fontFamily: fontFamilyMap[fontFamilies.headingXs],
      fontWeight: fontWeights.headingXs,
      letterSpacing: letterSpacings.headingXs,
      textTransform: 'uppercase',

      ...typeScale(0),
    },

    subtitle1: {
      fontFamily: fontFamilyMap[fontFamilies.bodyL],
      fontWeight: fontWeights.bodyL,

      ...typeScale(1.5),
    },
    subtitle2: {
      fontFamily: fontFamilyMap[fontFamilies.bodyM],
      fontWeight: fontWeights.bodyM,

      ...typeScale(0.5),
    },
    body1: {
      fontFamily: fontFamilyMap['Open Sans'],
      fontWeight: fontWeights.bodyS,
      letterSpacing: letterSpacings.bodyS,

      ...typeScale(0, true),
    },
    body2: {
      fontFamily: fontFamilyMap['Open Sans'],
      fontWeight: fontWeights.bodyS,

      ...typeScale(-0.5, true),
    },
    button: {
      fontFamily: fontFamilyMap[fontFamilies.button],
      fontWeight: 900,
      textTransform: 'uppercase',
      letterSpacing: '0.1em',

      ...typeScale(0.5),
    },
    caption: {
      fontFamily: fontFamilyMap[fontFamilies.headingXs],
      fontWeight: fontWeights.headingXs,

      ...typeScale(-1),
    },
    overline: {
      ...typeScale(-0.5),
    },
  },
  zIndex: {},
}
export const tokens = createMuiTheme(merge({}, defaultTheme, options))
