import type { Theme } from '@material-ui/core'
import { Card, CardContent } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/styles/makeStyles'
import type { FullContactAdresse } from '@willig/types/api'
import * as qs from 'query-string'
import type { Record, Identifier } from 'ra-core'
import { useGetMany } from 'ra-core'
import Link from 'ra-ui-materialui/lib/Link'
import { isEmpty } from 'rambda'

import { useGetList } from 'src/libs/useGetList'

const useStyles = makeStyles((theme: Theme) => {
  return {
    contactCard: {
      margin: `${theme.spacing(2)}px 0`,
    },
  }
})

export const ThreeCX = (props: any) => {
  const parsedQuery = qs.parse(props.location.search)
  const { ids } = parsedQuery
  const parsedIds = (ids as string)?.split(',')

  const { data, loading } = useGetMany('Contact', parsedIds, {
    enabled: Boolean(parsedIds),
  })
  if (loading) {
    return <p>chargement...</p>
  }
  if (data.every((d) => d === undefined)) {
    return <p>aucune donnée</p>
  }
  return (
    <div>
      {data.map((d) => {
        if (!d) {
          return null
        }
        return <ContactCard key={d.id} contact={d} />
      })}
    </div>
  )
}

type ContactCardProps = {
  contact: Record
}
function ContactCard(props: ContactCardProps) {
  const { contact } = props
  const { contactCard } = useStyles()
  return (
    <Card className={contactCard}>
      <CardContent>
        <Box display={'flex'} justifyContent={'space-between'}>
          <h3>{`${contact.nom} ${contact.prenom}`}</h3>
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to={`/Contact/${contact.id}`}
          >
            <>Voir le contact</>
          </Button>
        </Box>

        <SingleContact contact_id={contact.id} />
      </CardContent>
    </Card>
  )
}

type SingleContactProps = {
  contact_id: Identifier
}

function SingleContact(props: SingleContactProps) {
  const { contact_id } = props
  const { data, loading } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: { contact_id: contact_id },
    },
    { enabled: Boolean(contact_id) },
  )

  if (loading) {
    return <p>chargement...</p>
  }

  if (isEmpty(data)) {
    return <p>Acune adresse lié à ce contact</p>
  }

  return (
    <Box>
      {data.map((d) => {
        return (
          <div key={d.id}>
            <p>Adresse : {`${d.rue} ${d.code_postal} ${d.ville}`}</p>
          </div>
        )
      })}
    </Box>
  )
}
