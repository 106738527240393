import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Description as DescriptionIcon } from '@material-ui/icons'
import type { AxiosResponse } from 'axios'
import type { FormEvent } from 'react'
import React, { useState, useEffect } from 'react'
import { useNotify } from 'react-admin'
import type { FileWithPath } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'

type DropzoneProps = {
  onSuccess?: (data: AxiosResponse<any>) => any
  onError?: (error: any) => any
  onSettled?: (data: PromiseSettledResult<AxiosResponse<any> | void>[]) => any
  singleFile?: boolean
  className: string
  endpoint: string
}

export function Dropzone(props: DropzoneProps) {
  const { onSuccess, onSettled, onError, singleFile, className, endpoint } =
    props
  const [files, setFiles] = useState<FileWithPath[]>([])
  const notify = useNotify()
  const onDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: FileWithPath) => {
      setFiles((prevFiles) => [...prevFiles, file])
    })
  }

  const filesNumber = () => {
    if (singleFile) {
      return 1
    }
    return undefined
  }
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv,.xlsx',
    maxFiles: filesNumber(),
    maxSize: 10000000,
    onDrop,
    onDropRejected(file) {
      file.forEach((f) => {
        const filename = f.file.name
        f?.errors.forEach((e) =>
          notify(`Erreur sur le fichier ${filename}: ${e.message}`, 'error'),
        )
      })
    },
  })

  useEffect(
    // eslint-disable-next-line @getify/proper-arrows/return
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: FileWithPath) => URL.revokeObjectURL(file.name))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [isUploading, setIsUploading] = useState(false)
  const nxHttpClient = useNxHttpClient()

  const uploadImages = (e: FormEvent) => {
    e.preventDefault()
    if (files.length === 0) return notify('Aucun fichier ajouté', 'error')

    setIsUploading(true)
    Promise.allSettled(
      files.map((file) => {
        const formData: FormData = new FormData()

        formData.append('file', file, file.name)
        formData.append('type', 'import')
        return nxHttpClient
          .post(endpoint, formData)

          .then(function (response) {
            onSuccess?.(response)
            setIsUploading(false)
            return response
          })
          .catch(function (error) {
            onError?.(error.message)
          })
      }),
    )
      .then((res) => {
        onSettled?.(res)
        setFiles([])
      })
      .catch((error) => onError?.(error.message))
  }

  return (
    <Card className={className}>
      <CardContent>
        <form onSubmit={uploadImages}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
              <>
                {files.map((file: FileWithPath, index) => (
                  <Card key={index}>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <DescriptionIcon />
                        <p>{file.name}</p>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </>
            ) : (
              <Box py={5} textAlign="center">
                <span className="dropzone-placeholder">
                  Déposez des fichiers Excel ou cliquez pour en sélectionner.
                </span>
              </Box>
            )}
          </div>

          <Button fullWidth type="submit" variant="contained" color="primary">
            {isUploading
              ? 'Chargement...'
              : 'Sauvegarder et intégrer le fichier'}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}
