import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'

import '@fullcalendar/react' // Need import because else it crashed
import './index.css'
import { Env } from './adapters/env'
import { App } from './App'
import { config } from './config'
import reportWebVitals from './reportWebVitals'

const env = Env()
console.log(env)

if (!env.IS_DEV) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    environment: env.APP_ENV,
    enabled: !env.IS_DEV,
    release: config.APP_RELEASE,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
