import type { Theme } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { fromUnixTime } from 'date-fns'

import type { Dispatch, SetStateAction } from 'react'

const useStyles = makeStyles((theme: Theme) => {
  return {
    paper: {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      position: 'absolute',
      width: 800,
      maxHeight: '80%',
      overflowY: 'scroll',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  }
})

type MessagesHistoryModalProps = {
  toggleModal: Dispatch<SetStateAction<boolean>>
  modalState: boolean
  historicData: any
}

export function MessagesHistoryModal(props: MessagesHistoryModalProps) {
  const { toggleModal, modalState, historicData } = props
  const classes = useStyles()
  const handleClose = () => {
    toggleModal(false)
  }

  return (
    <Modal open={modalState} onClose={handleClose}>
      <div className={classes.paper}>
        <Typography style={{ marginBottom: '8px' }} variant="overline">
          {`Historique des échanges (${historicData?.length} messages)`}
        </Typography>
        {historicData.map((message: any) => {
          return (
            <p style={{ margin: '0' }} key={message.id}>
              <strong>{`Le ${dateFormatter(message.create_dt)} :`}</strong>{' '}
              {`${message.subject} (${message.type})`}
            </p>
          )
        })}
      </div>
    </Modal>
  )
}
function dateFormatter(date: number) {
  return new Date(fromUnixTime(date)).toLocaleDateString('fr', {
    dateStyle: 'long',
  })
}
