import Box from '@material-ui/core/Box'
import React from 'react'
import type { EditProps } from 'react-admin'
import { SimpleForm, Edit, SaveButton } from 'react-admin'

import { CreateEditInputs } from './CreateEditInputs'

export const EditView = (props: EditProps) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={
        <Box display="flex" gridGap={16} p={2}>
          <SaveButton />
        </Box>
      }
    >
      <CreateEditInputs />
    </SimpleForm>
  </Edit>
)
