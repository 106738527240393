import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Clear as ClearIcon } from '@material-ui/icons'
import type { Adress, Contact } from '@willig/types/api'
import { debounce } from 'lodash'
import queryString from 'query-string'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useEffect, useCallback, useMemo, useState } from 'react'

import type { Record } from 'react-admin'
import { useForm } from 'react-final-form'
import type { BasicDoc, Hit, SearchBoxProvided } from 'react-instantsearch-core'
import {
  InstantSearch,
  Hits,
  Highlight,
  connectSearchBox,
} from 'react-instantsearch-dom'
import { useEnv } from 'src/adapters/env'
import { SearchClient } from 'src/adapters/typesense'

import { concatAddress, concatNames } from '../../libs/concatNames'

const useStyles = makeStyles((theme) => {
  return {
    '@global': {
      '.ais-Highlight-highlighted': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '.ais-Hits-list': {
        listStyle: 'none',

        padding: 0,
      },
      p: {
        margin: 0,
      },
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    cardContent: {
      flexGrow: 1,
    },
  }
})

export type IndexName = 'adresses' | 'contacts'
type PropsWithChildren<P> = P & { children?: ReactNode }
type TypesenseInputProps = {
  source?: string
  indexName: IndexName
  inputLabel?: string
  data?: Record | undefined
  emptyInputArray?: string[]
  selectDataCallback?: (id: string) => any
  searchQuery?: string
  isDisabled?: boolean
}

export const TypesenseInput = (props: TypesenseInputProps) => {
  const {
    source,
    indexName,
    inputLabel,
    data,
    emptyInputArray,
    selectDataCallback,
    searchQuery,
    isDisabled = false,
  } = props
  const [focused, setFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const env = useEnv()
  const searchClient = useMemo(() => SearchClient({ env }), [env])
  const hitComponent = useCallback(
    (propsHit) => (
      <HitComponent
        {...propsHit}
        target={source}
        setInputValue={setInputValue}
        indexName={indexName}
        selectDataCallback={selectDataCallback}
      />
    ),
    [source, setInputValue, indexName, selectDataCallback],
  )
  useEffect(() => {
    if (data) {
      switch (indexName) {
        case 'contacts':
          setInputValue(concatNames(data as Contact))
          break
        case 'adresses':
          setInputValue(concatAddress(data as Adress))
          break
      }
    }
  }, [data, indexName])
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <CustomSearchBox
        setInputValue={setInputValue}
        inputValue={inputValue}
        setFocused={setFocused}
        indexName={indexName}
        inputLabel={inputLabel || ''}
        emptyInputArray={emptyInputArray}
        searchQuery={searchQuery}
        isDisabled={isDisabled}
      />
      {focused && <Hits hitComponent={hitComponent} />}
    </InstantSearch>
  )
}

type SearchBoxCustomProps = SearchBoxProvided & {
  setInputValue: Dispatch<SetStateAction<string>>
  inputValue: string
  setFocused: Dispatch<SetStateAction<boolean>>
  indexName: IndexName
  inputLabel?: string
  emptyInputArray?: string[]
  searchQuery?: string
  isDisabled?: boolean
}

const SearchBoxCustom = (props: SearchBoxCustomProps) => {
  const {
    refine,
    setInputValue,
    inputValue,
    setFocused,
    inputLabel,
    emptyInputArray,
    searchQuery,
    isDisabled: isInputDisabled = false,
  } = props
  const refineDebounced = useMemo(() => debounce(refine, 200), [refine])
  const form = useForm()
  const onChange = useCallback(
    (event: any) => {
      const { value } = event.target
      refineDebounced(value)
      setInputValue(value)
    },
    [refineDebounced, setInputValue],
  )
  const emptyInput = () => {
    setInputValue('')
    emptyInputArray?.forEach((input: string) => form.change(input, null))
  }

  // const [isDisabled, toggleIsDisabled] = useState<boolean>(false)

  const isDisabled = useMemo(() => {
    if (isInputDisabled) return true
    if (searchQuery) {
      const parsedQueries = queryString.parse(searchQuery)
      if (parsedQueries.adress_id !== undefined) {
        return true
      }
      return false
    }
    return false
  }, [searchQuery, isInputDisabled])

  const cleanInputValue = inputValue.replace('undefined', '')

  return (
    <>
      <TextField
        value={cleanInputValue}
        variant="filled"
        autoComplete="none"
        fullWidth
        disabled={isDisabled}
        onChange={onChange}
        label={inputLabel}
        onFocus={() => setFocused(true)}
        onBlur={() => setTimeout(() => setFocused(false), 200)}
        InputProps={{
          endAdornment: inputValue && !isDisabled && (
            <IconButton onClick={emptyInput} aria-label="delete">
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </>
  )
}

const CustomSearchBox = connectSearchBox(SearchBoxCustom)
type HitComponentProps = {
  target: string
  setInputValue: Dispatch<SetStateAction<string>>
  indexName: IndexName
  selectDataCallback?: (id: string) => any
}
const HitComponent = (
  props: PropsWithChildren<{ hit: Hit<BasicDoc> }> & HitComponentProps,
) => {
  const { hit, target, setInputValue, indexName, selectDataCallback } = props
  const classes = useStyles()
  const { card, cardContent } = classes
  const form = useForm()
  const inputValueSelected =
    indexName === 'adresses'
      ? `${hit.rue} ${hit.code_postal} ${hit.ville}`
      : `${hit.prenom} ${hit.nom}`
  const onClick = useCallback(() => {
    if (selectDataCallback) {
      selectDataCallback(hit.id)
      setInputValue(inputValueSelected)
    } else {
      form.change(target, hit.id)
      setInputValue(inputValueSelected)
    }
  }, [
    form,
    hit.id,
    inputValueSelected,
    selectDataCallback,
    setInputValue,
    target,
  ])

  const cardContentSwitch = () => {
    switch (indexName) {
      case 'contacts':
        return (
          <Card variant="outlined" className={card} onClick={onClick}>
            <CardContent className={cardContent}>
              <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr">
                <Box>
                  <Highlight tagName="mark" attribute="prenom" hit={hit} />
                </Box>
                <Box>
                  <Highlight tagName="mark" attribute="nom" hit={hit} />
                </Box>
                <Box>
                  {(hit?.phones as unknown as string[])?.map(
                    (phone: string) => (
                      <p key={phone}>{phone}</p>
                    ),
                  )}
                </Box>
                <Box>
                  <Highlight
                    tagName="mark"
                    attribute="raison_sociale"
                    hit={hit}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="small"
              >
                Choisir
              </Button>
            </CardActions>
          </Card>
        )

      case 'adresses':
        return (
          <Card variant="outlined" className={card} onClick={onClick}>
            <CardContent className={cardContent}>
              <Box
                display="grid"
                gridGap={8}
                gridTemplateColumns="2fr 1fr 1fr 2fr 2fr"
              >
                <Box>
                  <Highlight tagName="mark" attribute="rue" hit={hit} />
                </Box>
                <p>{hit.code_postal}</p>
                <p>{hit.ville}</p>
                <p>{hit.contact}</p>
                <Box>
                  <Highlight tagName="mark" attribute="note" hit={hit} />
                </Box>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                size="small"
              >
                Choisir
              </Button>
            </CardActions>
          </Card>
        )
    }
  }
  return cardContentSwitch()
}
