import { Button, Input } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import type { FormData } from './Modal'
import { useModalStyle } from './Modal'

type SmsFormProps = {
  onSubmit: (data: FormData) => void
  count: number
}

export function SmsForm(props: SmsFormProps) {
  const { onSubmit, count } = props
  const { handleSubmit, control } = useForm({
    defaultValues: {
      subject: '',
    },
  })
  const { rootButton, modal } = useModalStyle()
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={modal}>
      <Controller
        name="subject"
        control={control}
        rules={{ required: true }}
        render={(renderProps) => {
          const { field } = renderProps
          return <Input multiline rows={3} {...field} />
        }}
      />
      <Button
        type="submit"
        classes={{ root: rootButton }}
      >{`envoyer ${count} sms`}</Button>
    </form>
  )
}
