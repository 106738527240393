import type { ProfunctorState } from '@staltz/use-profunctor-state'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useCallback } from 'react'
import { required } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

import type { isProState } from '../interventionForm'

import { BillingAdress } from './BillingAdresse'
import ContactReferenceInput from './ContactReferenceInput'

type InvoiceContactInfoProps = {
  setSearchFacture: Dispatch<SetStateAction<boolean>>
  searchFacture: boolean
  state: ProfunctorState<isProState>
  isDisabled: boolean
}
export const InvoiceContactInfo = (props: InvoiceContactInfoProps) => {
  const { setSearchFacture, searchFacture, state, isDisabled = false } = props
  const { values } = useFormState({ subscription: { values: true } })
  const form = useForm()

  useEffect(() => {
    if (!values.facture_contact_id) {
      form.change('facture_adresse_id', null)
      setSearchFacture(false)
    }
  }, [values.facture_contact_id, setSearchFacture, form])
  const RequiredUseMemo = useMemo(() => required(), [])

  const filterToQuery = useCallback((searchText: string) => {
    return {
      nom: searchText,
    }
  }, [])
  return (
    <>
      <ContactReferenceInput
        isDisabled={isDisabled}
        inputLabel="Contact de facturation"
        target="facture_contact_id"
        source="facture_contact_id"
        reference="Contact"
        validate={RequiredUseMemo}
        perPage={10}
        filterToQuery={filterToQuery}
        emptyContactInput={['facture_contact_id']}
        stateProf={state}
      />
      <BillingAdress
        state={state.state}
        searchFacture={searchFacture}
        setSearchFacture={setSearchFacture}
      />
    </>
  )
}
