import type { StatusIntervention } from '@willig/types/api'
import { useDataProvider } from 'react-admin'
import type { UseQueryOptions } from 'react-query'
import { useQuery } from 'react-query'

export function useInterventionStatus(
  status: number,
  options?: UseQueryOptions,
) {
  const dataProvider = useDataProvider()
  const { data: interventionStatus } = useQuery<StatusIntervention>(
    ['StatusIntervention', status],
    async () => {
      const { data } = await dataProvider.getOne('StatusIntervention', {
        id: status,
      })
      return data as StatusIntervention
    },
    { staleTime: Infinity, ...(options as any) },
  )

  return interventionStatus
}
