import { Draggable } from '@fullcalendar/interaction'

import { makeStyles } from '@material-ui/core/styles'
import type { SpecialEventsType } from '@willig/types/api'
import React, { useEffect, useRef } from 'react'

import { v4 as uuidv4 } from 'uuid'

import { useGetList } from '../../libs/useGetList'

const useStyles = makeStyles((theme) => {
  return {
    specialEventTypesContainer: {
      minWidth: '250px',
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
      border: `solid 1px ${theme.palette.primary.contrastText}`,
      borderRadius: '4px',
      height: 'calc(100vh - 140px)',
      padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
    eventTypeItem: {
      borderRadius: '4px',
      padding: `${theme.spacing(2)}px 0`,
      textAlign: 'center',
    },
  }
})

export const SpecialEventTypeContainer = () => {
  const { specialEventTypesContainer, eventTypeItem } = useStyles()
  const { data: specialEventsTypes } = useGetList<SpecialEventsType>(
    'SpecialEventsType',
    {},
  )

  const eventsContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (eventsContainer.current) {
      const draggableContainer = new Draggable(eventsContainer.current, {
        itemSelector: '.item-class',
        eventData: function (eventEl) {
          const extendedProps = {
            eventId: eventEl.getAttribute('data-special-event-id'),
            eventType: eventEl.getAttribute('data-event-type'),
            typeId: eventEl.getAttribute('data-event-id'),
            title: eventEl.getAttribute('data-title'),
            duration: '01:00',
            backgroundColor: eventEl.getAttribute('data-event-bg-color'),
            borderColor: eventEl.getAttribute('data-event-bg-color'),
            textColor: eventEl.getAttribute('data-event-text-color'),
          }
          return extendedProps
        },
      })
      return () => {
        draggableContainer.destroy()
      }
    }
  })

  return (
    <div className={specialEventTypesContainer} ref={eventsContainer}>
      {specialEventsTypes.map((type) => {
        const { id, label, bg_color, text_color } = type
        return (
          <div
            data-special-event-id={uuidv4()}
            data-title={`${label}`}
            data-event-type={'specialEvent'}
            data-event-bg-color={`${bg_color}`}
            data-event-text-color={`${text_color}`}
            data-event-id={`${id}`}
            className={`item-class ${eventTypeItem}`}
            style={{ backgroundColor: bg_color, color: text_color }}
            key={id}
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}
