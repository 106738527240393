import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

type CustomNotificationProps = {
  errorMessage: string
  open: boolean
  setOpen: any
}

export function CustomNotification(props: CustomNotificationProps) {
  const { errorMessage, open, setOpen } = props

  function handleClose() {
    setOpen(false)
  }

  return (
    <Snackbar open={open} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="error"
      >
        {errorMessage}
      </MuiAlert>
    </Snackbar>
  )
}
