import { colors } from '@willig/design/tokens'
import type { RaThemeOverrides } from 'react-admin'

import { tokens } from './tokens'

const formWrapper = {
  root: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}

export const overrides: RaThemeOverrides = {
  RaLayout: {
    appFrame: {
      '@media (min-width: 0px)': {
        marginTop: tokens.spacing(7),
      },
    },
    content: {
      padding: 0,
      '@media (min-width: 0px)': {
        padding: tokens.spacing(0),
      },
      [tokens.breakpoints.up('sm')]: {
        padding: tokens.spacing(0),
      },
      [tokens.breakpoints.up('md')]: {
        padding: tokens.spacing(0),
      },
    },
  },
  RaLink: {
    link: {
      textDecoration: 'underline',
    },
  },

  RaLabeled: {
    value: {
      padding: 0,
    },
  },
  RaCreate: {
    ...formWrapper,
  },
  RaEdit: {
    ...formWrapper,
  },

  RaAppBar: {
    toolbar: {
      backgroundColor: tokens.palette.primary.light,
      color: tokens.palette.primary.contrastText,
      paddingLeft: tokens.spacing(1),
      minHeight: tokens.spacing(7),
    },
  },

  RaSidebar: {
    root: {
      height: '100vh-3em',
      width: '96px',
    },
    drawerPaper: {
      paddingRight: 0,
      width: '96px',
    },
    fixed: {
      position: 'relative',
      zIndex: '100',
      height: '100%',
      maxWidth: '96px',
    },
  },
  RaMenu: {
    closed: {
      width: '100px',
    },
  },
  RaMultiLevelMenu: {
    navWithCategories: { backgroundColor: colors.gray },
  },
  RaMenuItemCategory: {
    root: { color: colors.surface },
    active: {
      backgroundColor: tokens.palette.secondary.main,
      color: colors.surface,
    },
  },
  RaMenuItemLink: {
    active: {
      backgroundColor: tokens.palette.secondary.main,
    },
  },
  MuiInputBase: {
    inputMultiline: {
      minHeight: '50px',
    },
  },
  MuiButton: {
    containedPrimary: {
      backgroundColor: tokens.palette.primary.light,
      color: tokens.palette.primary.contrastText,
    },
  },
}
