import { sanitize } from 'dompurify'
import { useMemo } from 'react'

// eslint-disable-next-line no-undef
type Props = Omit<JSX.IntrinsicElements['span'], 'children'> & {
  children?: string
}

export function SafeHtml(props: Props) {
  const { children, ...rest } = props

  const sanitizedHTML = useMemo(() => {
    if (!children) return
    return { __html: sanitize(children) }
  }, [children])

  if (!sanitizedHTML) return null
  return <span {...rest} dangerouslySetInnerHTML={sanitizedHTML} />
}
