import React from 'react'
import type { ListProps } from 'react-admin'
import { Datagrid, List, TextField, BulkExportButton } from 'react-admin'

export const ListView = (props: ListProps) => (
  <List {...props} bulkActionButtons={<BulkExportButton />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="label" />
    </Datagrid>
  </List>
)
