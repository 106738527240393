import type { Contact, Adress } from '@willig/types/api'

export function concatNames(record?: Contact) {
  if (!record) return ''
  const name = [record?.prenom, record?.nom].filter(Boolean).join(' ')
  return [record?.raison_sociale, name].filter(Boolean).join(' - ')
}

export function concatAddress(record?: Adress) {
  if (!record) return ''
  const name = [record?.rue, record?.code_postal, record?.ville]
    .filter(Boolean)
    .join(' ')

  const complement = record?.cote ? `( ${record?.cote} )` : undefined

  return [name, complement].filter(Boolean).join(' ')
}
