import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'

import { required, SelectInput, TextInput } from 'react-admin'

import { theme } from 'src/UI/theme'

const useStyles = makeStyles({
  relationForm: {
    gap: '1rem',
    borderColor: theme.palette.primary.main,
  },
})

type selectRelationshipProps = {
  targetRelationType: 'contact_relation_type' | 'invoice_relation_type'
  targetCommentType: 'contact_relation_comment' | 'invoice_relation_comment'
  initialValue?: string
}
export const SelectRelationship = (props: selectRelationshipProps) => {
  const { targetRelationType, targetCommentType, initialValue } = props
  const classes = useStyles()
  const validate = useMemo(() => required(), [])
  return (
    <Box
      display="flex"
      alignItems="center"
      border={1}
      borderRadius={4}
      px={2}
      py={2}
      className={classes.relationForm}
    >
      <SelectInput
        initialValue={initialValue}
        validate={validate}
        label="Relation"
        source={targetRelationType}
        choices={[
          { id: 'locataire', name: 'locataire' },
          { id: 'proprietaire', name: 'proprietaire' },
          { id: 'syndic', name: 'syndic' },
          { id: 'marche', name: 'marche' },
          { id: 'contact', name: 'contact' },
        ]}
      />
      <TextInput fullWidth source={targetCommentType} label="Commentaire" />
    </Box>
  )
}
