import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import type { Pro, Adress } from '@willig/types/api'
import type { Record, Identifier } from 'ra-core'
import { required } from 'ra-core'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  DateInput,
} from 'react-admin'
import { useOne } from 'src/libs/useOne'

enum StatusInterventionEnum {
  'RDV_PRIS' = 1,
}

type StaticFieldsProps = {
  record?: Record & { adresse_id: Identifier }
}

export const StaticFields = (props: StaticFieldsProps) => {
  const { record } = props

  const { data: adresse } = useOne<Adress>(
    'Adress',
    {
      id: record?.adresse_id ?? '',
    },
    { enabled: Boolean(record?.adresse_id) },
  )
  const { data: pro } = useOne<Pro>(
    'Pro',
    {
      id: adresse?.data.pro_id ?? '',
    },
    { enabled: Boolean(adresse?.data.pro_id) },
  )

  return (
    <Card variant="outlined">
      <CardContent>
        <Box style={{ padding: '16px 0' }}>
          <Typography variant="button" color="primary">
            {pro?.data.name}
          </Typography>
        </Box>
        <Box display="flex" gridGap={16}>
          <DateInput fullWidth source="rdv_date" validate={required()} />
          <TextInput
            type="time"
            fullWidth
            source="rdv_time"
            validate={required()}
          />
        </Box>
        <Box>
          <ReferenceInput
            fullWidth
            label="Ramoneur"
            source="sweep_id"
            reference="User"
            filter={{ '<access_level': [50], '>access_level': [9] }}
            sort={{ field: 'access_level', order: 'DESC' }}
            validate={required()}
          >
            <SelectInput optionText="login" />
          </ReferenceInput>
          <SelectInput
            validate={required()}
            fullWidth
            source="duration"
            optionValue="duration"
            choices={[
              { duration: '30', name: '30 minutes' },
              { duration: '45', name: '45 minutes' },
              { duration: '60', name: '1 heure' },
              { duration: '90', name: '1 heure et demi' },
              { duration: '120', name: '2 heure' },
              { duration: '180', name: '3 heure' },
              { duration: '240', name: '4 heure' },
              { duration: '300', name: '5 heure' },
              { duration: '360', name: '6 heure' },
            ]}
          />
          <ReferenceInput
            initialValue={StatusInterventionEnum.RDV_PRIS}
            fullWidth
            source="status"
            filter={{
              id: [1, 3, 4, 5, 6, 7],
            }}
            reference="StatusIntervention"
          >
            <SelectInput optionText="label" />
          </ReferenceInput>
          <TextInput
            fullWidth
            multiline
            source="infos"
            label={'Informations utiles - Résumé des travaux réalisés'}
          />
          <TextInput
            fullWidth
            multiline
            source="prerequisites"
            label={"Prérequis (à faire avant l'intervention)"}
          />
          <BooleanInput label="Créneau fixe" source="fixed_slot" />
        </Box>
      </CardContent>
    </Card>
  )
}
