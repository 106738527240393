import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import { SelectInput, TextInput } from 'react-admin'

export const CreateEditInputs = () => {
  const choices = [
    { name: 'Administrateur', id: 100 },
    { name: 'Ramoneur', id: 20 },
    { name: 'Apprenti', id: 10 },
  ]
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="login" />
            <TextInput fullWidth source="password" />
          </Box>
          <Box>
            <SelectInput fullWidth source="access_level" choices={choices} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
