import { Draggable } from '@fullcalendar/interaction'
import { Chip, useTheme } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import type { Pro } from '@willig/types/api'
import { useEffect, useState, useRef } from 'react'
import { useGetList } from 'src/libs/useGetList'

import { DraggableContainer } from './DraggableContainer'
import type { EventPayload } from './SweepCalendar'

const useStyles = makeStyles((theme) => {
  return {
    proContainer: {
      minWidth: '250px',
      display: 'flex',
      flexDirection: 'column',
      gap: `${theme.spacing(1)}px`,
      border: `solid 1px ${theme.palette.primary.contrastText}`,
      borderRadius: '4px',
      height: 'calc(100vh - 140px)',
      overflow: 'scroll',
    },
    accordion: {
      backgroundColor: theme.palette.grey[100],
    },
    chipContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },

    greenContract: {
      backgroundColor: theme.palette.primary.light,
      width: 'fit-content',
    },
    yellowContract: {
      backgroundColor: theme.palette.warning.light,
      width: 'fit-content',
    },
    redContract: {
      backgroundColor: theme.palette.error.light,
      width: 'fit-content',
    },
    undefinedEcheanceContract: {
      backgroundColor: theme.palette.secondary.light,
      width: 'fit-content',
    },
  }
})
export const ProContainer = () => {
  const container = useRef<HTMLDivElement | null>(null)
  const classes = useStyles()
  const theme = useTheme()

  const { data: pros } = useGetList<Pro>('Pro', {
    pagination: { page: 0, perPage: 0 },
  })
  useEffect(() => {
    if (container.current) {
      const draggableContainer = new Draggable(container.current, {
        itemSelector: '.item-class',
        eventData: function (eventEl) {
          const extendedProps = {
            eventType: eventEl.getAttribute('data-event-type'),
            title: eventEl.getAttribute('data-title'),
            duration: '01:00',
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            textColor: theme.palette.primary.contrastText,

            payload: JSON.parse(
              eventEl.getAttribute('data-payload') ?? '',
            ) as EventPayload,
          }
          return extendedProps
        },
      })
      return () => {
        draggableContainer.destroy()
      }
    }
  })
  const [selectedPro, setPro] = useState<string | undefined>()
  const toggle = (pro_id: string) => {
    setPro((previous) => {
      if (previous === pro_id) {
        return undefined
      }
      return pro_id
    })
  }

  return (
    <div className={classes.proContainer} ref={container}>
      <div className={classes.chipContainer}>
        <Chip
          label="Echeance dans plus d'un mois"
          className={classes.greenContract}
        />
        <Chip
          label="Echeance dans les 30 jours"
          className={classes.yellowContract}
        />
        <Chip label="Echéance passée" className={classes.redContract} />
      </div>
      {pros.map((pro) => (
        <Accordion
          className={classes.accordion}
          expanded={selectedPro === pro.id}
          key={pro.id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => toggle(pro.id)}
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 10,
              background: theme.palette.background.default,
            }}
          >
            {pro.name}
          </AccordionSummary>
          {pro.id === selectedPro && <DraggableContainer pro_id={pro.id} />}
        </Accordion>
      ))}
    </div>
  )
}
