import { Typography, Box } from '@material-ui/core'
import type { Intervention } from '@willig/types/api'
import { Suspense } from 'react'

import { useList } from '../libs/useGetList'

import { InterventionCard } from './InterventionCard'

type InterventionListProps = {
  adresse_id: string | number
}

export const InterventionList = (props: InterventionListProps) => {
  const { adresse_id } = props
  const { data: interventions, isLoading: loading } = useList<Intervention>(
    'Intervention',
    {
      filter: { adresse_id: [adresse_id] },
      pagination: { page: 0, perPage: 52 },
      sort: { order: 'DESC', field: 'rdv_date' },
    },
    { enabled: Boolean(adresse_id) },
  )

  if (loading) {
    return <p>Chargement...</p>
  }

  return (
    <Box mt={2} display="flex" gridGap={16} flexDirection="column">
      <Typography variant="overline">Historique des interventions</Typography>
      <Suspense fallback={<p>Chargement...</p>}>
        {!interventions?.data?.length && <p>Aucun historique</p>}
        {interventions?.data.map((intervention) => (
          <InterventionCard key={intervention.id} intervention={intervention} />
        ))}
      </Suspense>
    </Box>
  )
}
