import Card from '@material-ui/core/Card'
import type { FullContactAdresse } from '@willig/types/api'
import { useFormState } from 'react-final-form'
import { useGetList } from 'src/libs/useGetList'

import type { EntriesStatus } from './OtherRelationsActions'
import { OtherRelationActions } from './OtherRelationsActions'
import { SelectRelationship } from './SelectRelationShip'

type RelationshipsProps = {
  otherEntriesState: EntriesStatus
  setOtherEntriesState: (
    update: (entries: EntriesStatus) => EntriesStatus,
  ) => void
}

export const Relationships = (props: RelationshipsProps) => {
  const { otherEntriesState, setOtherEntriesState } = props
  const { values } = useFormState({ subscription: { values: true } })

  const showAdresssContactRelationship = Boolean(
    values.adresse_id && values.contact_id,
  )
  const showAdresssInvoiceContactRelationship = Boolean(
    values.adresse_id && values.facture_adresse_id,
  )

  const { data: residentRelationship } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        contact_id: [values.contact_id],
        '!deleted': [1],
        adresse_id: [values.adresse_id],
      },
    },
    { enabled: Boolean(values.contact_id && values.adresse_id) },
  )

  const { data: invoicerRelationship } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: {
        contact_id: [values.facture_contact_id],
        '!deleted': [1],
        adresse_id: [values.adresse_id],
      },
    },
    { enabled: Boolean(values.facture_contact_id && values.adresse_id) },
  )

  return (
    <Card>
      {showAdresssContactRelationship && (
        <>
          <h3>Relations entre adresse et contact</h3>
          {residentRelationship.length > 1 && (
            <h6>
              plusieurs relation existent le champs ne peut pas être prérempli
            </h6>
          )}
          <SelectRelationship
            initialValue={
              residentRelationship.length === 1
                ? residentRelationship[0].type_relation
                : undefined
            }
            targetCommentType="contact_relation_comment"
            targetRelationType="contact_relation_type"
          />
          <OtherRelationActions
            adresse_id={values.adresse_id}
            contact_id={values.contact_id}
            otherEntriesState={otherEntriesState}
            setOtherEntriesState={setOtherEntriesState}
          />
        </>
      )}
      {showAdresssInvoiceContactRelationship && (
        <>
          {invoicerRelationship.length > 1 && (
            <h6>
              plusieurs relation existent le champs ne peut pas être prérempli
            </h6>
          )}
          <h3>Relation entre adresse et contact de facturation</h3>
          <SelectRelationship
            initialValue={
              invoicerRelationship.length === 1
                ? invoicerRelationship[0].type_relation
                : undefined
            }
            targetCommentType="invoice_relation_comment"
            targetRelationType="invoice_relation_type"
          />
        </>
      )}
    </Card>
  )
}
