import React from 'react'
import type { ListProps } from 'react-admin'
import {
  BooleanField,
  Datagrid,
  List,
  TextField,
  BulkExportButton,
} from 'react-admin'

export const ListView = (props: ListProps) => {
  return (
    <List {...props} bulkActionButtons={<BulkExportButton />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="code" />
        <TextField source="label" />
        <BooleanField source="pour_installation" />
      </Datagrid>
    </List>
  )
}
