import { isEmpty } from 'rambda'

type filterPriceProps = {
  price_edited?: string | number
  price_computed?: string | number
  tva: string | number
}

export function handleInvoicePrices(invoice?: filterPriceProps) {
  if (!invoice) {
    return 'pas de facture'
  }
  if (isEmpty(invoice)) {
    return 'facturation manquante'
  }
  if (invoice.price_edited) {
    return `${invoice.price_edited}€ TTC`
  }
  if (invoice.price_computed) {
    return addTva(invoice.price_computed, invoice?.tva)
  }
  return 'pas de prix'
}

function addTva(price: string | number, tva: string | number) {
  if (!tva) {
    return `${price}€`
  }
  const parsedPrice = typeof price === 'string' ? parseFloat(price) : price
  const parsedTva = typeof tva === 'string' ? parseFloat(tva) : tva
  return `${Number(parsedPrice * (1 + parsedTva / 100)).toFixed(2)}€ TTC`
}
