import Drawer from '@material-ui/core/Drawer'

import {
  KeyboardArrowRight as IconKeyboardArrowRight,
  RemoveRedEye as IconImageEye,
} from '@material-ui/icons'
import React, { useState } from 'react'
import type { Record } from 'react-admin'
import {
  BooleanField,
  Button,
  EmailField,
  SimpleShowLayout,
  TextField,
} from 'react-admin'

const ContactQuickPreviewButton = (props: { data: Record }) => {
  const { data } = props
  const [showPanel, setShowPanel] = useState(false)

  const handleClick = () => {
    setShowPanel(true)
  }

  const handleCloseClick = () => {
    setShowPanel(false)
  }

  return (
    <>
      <Button onClick={handleClick} label="Voir">
        <IconImageEye />
      </Button>
      <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
        <div>
          <Button label="Fermer" onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </Button>
        </div>
        <SimpleShowLayout record={data} basePath="/posts" resource="posts">
          <TextField source="id" />
          <TextField source="sellsy_id" />
          <TextField source="prenom" />
          <TextField source="nom" />
          <TextField source="mobile" />
          <TextField source="fixe" />
          <EmailField source="email" />
          <BooleanField source="accept_sms" />
          <BooleanField source="accept_email" />
          <TextField source="type" />
          <TextField source="raison_sociale" />
          <TextField source="commentaire" />
          <BooleanField source="deleted" />
        </SimpleShowLayout>
      </Drawer>
    </>
  )
}

export default ContactQuickPreviewButton
