import { Button, TextareaAutosize, TextField } from '@material-ui/core'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { Picker } from 'src/components/Form'
import { useSibTemplates } from 'src/hooks/useSib'

import type { FormData } from './Modal'
import { useModalStyle } from './Modal'

type MailFormProps = {
  onSubmit: (data: FormData) => void
  count: number
}

export function MailForm(props: MailFormProps) {
  const { onSubmit, count } = props
  const methods = useForm({
    defaultValues: {
      template: '',
      freeContent: '',
      title: '',
    },
  })
  const { handleSubmit, control, watch } = methods
  const templates = useSibTemplates()
  const { modal, rootButton } = useModalStyle()

  const watchedOption = watch('template')

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={modal}>
        <Controller
          name="title"
          control={control}
          rules={{ required: true, minLength: 1 }}
          render={(renderProps) => {
            const { field } = renderProps
            return (
              <TextField
                variant="filled"
                style={{ marginBottom: '24px' }}
                required
                label="Title de l'email"
                defaultValue="Hello World"
                {...field}
              />
            )
          }}
        />
        <Picker
          options={[
            { value: '__blank', label: 'texte libre' },
            ...(templates
              .filter((template) => template !== '__layout')
              .map((template) => {
                return {
                  value: template,
                  label: template,
                }
              }) ?? []),
          ]}
          name="template"
          label="Modèles mail"
          required
        />
        {watchedOption === '__blank' && (
          <Controller
            name="freeContent"
            control={control}
            rules={{ required: true }}
            render={(renderProps) => {
              const { field } = renderProps
              return <TextareaAutosize rowsMin={8} rowsMax={16} {...field} />
            }}
          />
        )}
        <Button
          type="submit"
          classes={{ root: rootButton }}
        >{`envoyer ${count} Mails`}</Button>
      </form>
    </FormProvider>
  )
}
