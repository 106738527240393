import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'
import { Admin } from 'react-admin'
import { QueryClient, QueryClientProvider } from 'react-query'

import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import { AuthProvider } from './adapters/authProvider'
import { DataProvider } from './adapters/dataProvider'
import { Env, EnvContext } from './adapters/env'
import { NxHttpClient, NxHttpClientContext } from './adapters/nxHttpClient'
import { resources } from './ressources'
import { CustomRoutes } from './route'
import { theme, Layout } from './UI'

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr')
const queryClient = new QueryClient()
const env = Env()
const nxHttpClient = NxHttpClient({ env })
const dataProvider = antiCorruptionLayerProxy(DataProvider({ nxHttpClient }))
const authProvider = AuthProvider({ nxHttpClient })

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <EnvContext.Provider value={env}>
        <NxHttpClientContext.Provider value={nxHttpClient}>
          <Admin
            i18nProvider={i18nProvider}
            customRoutes={CustomRoutes}
            theme={theme}
            layout={Layout}
            title="Willig - Backoffice"
            disableTelemetry
            authProvider={authProvider}
            dataProvider={dataProvider}
          >
            {resources}
          </Admin>
        </NxHttpClientContext.Provider>
      </EnvContext.Provider>
    </QueryClientProvider>
  )
}
