import type { Theme } from '@material-ui/core'
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import type { FullStat } from '@willig/types/api'
import { useMemo, useState } from 'react'
import { useNxHttpClient } from 'src/adapters/nxHttpClient'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    tableView: {
      backgroundColor: theme.palette.primary.light,
      '& th': {
        fontWeight: 600,
        color: 'white',
        fontSize: '16px',
      },
    },
  }),
)

export function FullStats() {
  const [rawStats, setStats] = useState<FullStat[]>()
  const nxHttpClient = useNxHttpClient()
  const { tableView } = useStyles()
  useMemo(() => {
    nxHttpClient.get('/FullStat').then((response) => setStats(response.data))
  }, [nxHttpClient])

  const stats = useMemo(() => {
    return rawStats?.filter((stat) => stat.type === 'particulier')
  }, [rawStats])

  const proStat = useMemo(() => {
    return rawStats?.filter((stat) => stat.type === 'pro')
  }, [rawStats])

  function NoData() {
    return (
      <Typography variant="h6" style={{ margin: '20px 0' }}>
        Pas de données pour le moment ...
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="h3" style={{ margin: '20px 0' }}>
        Contrat/Syndic à venir
      </Typography>
      {!proStat || proStat.length === 0 ? (
        <NoData />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className={tableView}>
                {proStat.map((stat, index) => (
                  <TableCell key={index} component="th" scope="row">
                    {`${stat.mo}`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {proStat.map((stat, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    style={{ verticalAlign: 'text-top' }}
                  >
                    {parsed(stat, false)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="h3" style={{ margin: '20px 0' }}>
        Zones prioritaires
      </Typography>
      {!stats || stats.length === 0 ? (
        <NoData />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow className={tableView}>
                {stats.map((stat, index) => (
                  <TableCell key={index} component="th" scope="row">
                    {`${stat.mo}`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {stats.map((stat, index) => (
                  <TableCell
                    key={index}
                    component="th"
                    scope="row"
                    style={{ verticalAlign: 'text-top' }}
                  >
                    {parsed(stat, true)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

function parsed(stat: FullStat, isPro: boolean) {
  const splittedStat = stat.todo?.split(', ') ?? []
  const regex = /(.*) \((\d+)\)/
  return (
    <>
      {splittedStat.map((list: string) => {
        const [, city, count] = list.match(regex) ?? []
        const color = parseInt(count) > 50 ? '' : 'gray'
        const fontWeight = parseInt(count) > 50 ? 'bold' : ''

        if (isPro && parseInt(count) < 9) return <></>

        return (
          <p style={{ display: 'flex', gap: '8px', color, fontWeight }}>
            <span>{count}</span>
            {city}
          </p>
        )
      })}
    </>
  )
}
