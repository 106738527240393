import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import type { AppBarProps } from 'react-admin'
import { AppBar as RaAppBar } from 'react-admin'
import { GlobalSearch } from 'src/components/Typesense/GlobalSearch'

const useStyles = makeStyles({
  typo: {
    flex: 1,
  },
  sidebar: {
    width: 240,
    closedWidth: 55,
  },
})

export function AppBar(props: AppBarProps) {
  const classes = useStyles()
  return (
    <RaAppBar {...props}>
      <Typography
        className={classes.typo}
        variant="h6"
        id="react-admin-title"
      />

      <GlobalSearch />
    </RaAppBar>
  )
}
