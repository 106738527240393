import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { PersonAdd as PersonAddIcon } from '@material-ui/icons'

import type { FullContactAdresse } from '@willig/types/api'
import { SimpleShowLayout, TextField, FunctionField } from 'react-admin'
import { useGetList } from 'src/libs/useGetList'

type sugesstedResidentProps = {
  adresse_id: string
  onSelect: (choice: string | null) => void
}

const useStyles = makeStyles({
  suggestedCard: {
    minWidth: 275,
    display: 'flex',
    alignItems: 'flex-end',
  },
  suggestedInfo: {
    flexGrow: 1,
  },
})

export const SuggestedResident = (props: sugesstedResidentProps) => {
  const { adresse_id, onSelect } = props
  const { data, loading } = useGetList<FullContactAdresse>(
    'FullContactAdresse',
    {
      filter: { adresse_id: [adresse_id], '!deleted': 1, is_payor: [0] },
    },
    {
      enabled: Boolean(adresse_id),
      onSuccess(response: { data?: FullContactAdresse[] }) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data } = response
        if (data?.length === 1) {
          onSelect(data[0].contact_id)
        }
      },
    },
  )

  const classes = useStyles()

  if (loading) return <>Chargement..</>

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {data?.map((res: any) => (
        <Card key={res.id} className={classes.suggestedCard} variant="outlined">
          <CardContent className={classes.suggestedInfo}>
            <SimpleShowLayout record={res}>
              <FunctionField
                label="nom prenom"
                render={(record: any) =>
                  `${record.nom ?? ''} ${record.prenom ?? ''}`
                }
              />
              <TextField source="type_relation" />
            </SimpleShowLayout>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={() => onSelect(res.contact_id)}>
              Choisir
            </Button>
          </CardActions>
        </Card>
      ))}

      <Card className={classes.suggestedCard} variant="outlined">
        <CardContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSelect(null)}
          >
            <PersonAddIcon />
            <span>Créer ou sélectionner un contact</span>
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}
